import { type GetPlanungsinformationOutput } from '../../../../dtos';
import { type TablelikeRow } from '../../../general/Tablelike';
import { content } from './TerminPlanungsinformationenCard.content';
import { printTerminreihe, printVerknuepfung } from './TerminPlanungsinformationenCard.utils';
import { Link } from 'react-router';

type MusskopplungProps = {
  readonly planungsinformation: GetPlanungsinformationOutput;
};

const Musskopplung: React.FC<MusskopplungProps> = ({ planungsinformation }: MusskopplungProps) => {
  const musskopplungen = planungsinformation.musskopplungReihenfolgeAkaProduktIdsWithProduktSapIds;

  if (musskopplungen === null || musskopplungen.length === 0) {
    return content.noInfoAvailable;
  }

  return musskopplungen.map((musskopplung) => (
    <div key={musskopplung.akaProduktId}>
      <Link to={`/thema/${musskopplung.produktSapId}`}>{musskopplung.akaProduktId}</Link>
      <br />
    </div>
  ));
};

export const getThirdSectionTableRows = (planungsinformation: GetPlanungsinformationOutput): TablelikeRow[] => {
  const rows: TablelikeRow[] = [];

  if (planungsinformation.musskopplungReihenfolgeAkaProduktIdsWithProduktSapIds !== null && planungsinformation.musskopplungReihenfolgeAkaProduktIdsWithProduktSapIds.length > 0) {
    rows.push([content.fields.musskopplung, <Musskopplung planungsinformation={planungsinformation} key="musskopplung" />]);
  }

  if (planungsinformation.istTerminreihe === true) {
    rows.push([content.fields.terminreihe, printTerminreihe(planungsinformation)]);
  }

  if (planungsinformation.verknuepfungBLOPundPraesenzseminar !== null) {
    rows.push([content.fields.verknuepfung, printVerknuepfung(planungsinformation)]);
  }

  return rows;
};
