import { TrpcLoadingInfo } from '../../components/general/TrpcLoadingInfo';
import { ThemaResultCard } from '../../components/pages/search/thema/ThemaResultCard';
import { trpc } from '../../trpc';
import { Box, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router';

export const ThemaSearchPage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get('searchTerm') ?? '');
  const themen = trpc.search.searchThema.useQuery({ searchTerm }, { enabled: searchTerm.length > 0 });

  const updateSearchTerm = (term: string): void => {
    setSearchParams({ searchTerm: term });
    setSearchTerm(term);
  };

  return (
    <>
      <Stack direction="row" justifyContent="center" sx={{ marginTop: 4, marginBottom: 4 }}>
        <TextField label="Suche" onChange={(event) => updateSearchTerm(event.target.value)} sx={{ width: '50vw' }} value={searchTerm} variant="outlined" />
      </Stack>
      <Box sx={{ marginLeft: 4, paddingRight: 4 }}>
        <Stack direction="column" spacing={2}>
          <TrpcLoadingInfo trpcQuery={themen} entity="Themen">
            {themen.data?.map((thema) => <ThemaResultCard key={thema.id} thema={thema} />)}
          </TrpcLoadingInfo>
        </Stack>
      </Box>
    </>
  );
};
