import { EasyBreadcrumbs, type EasyBreadcrumbsItem } from '../../components/general/EasyBreadcrumbs';
import { AutomatisierteTerminanfragenTable } from '../../components/pages/reporting/AutomatisierteTerminanfragenTable';
import { type GetAutomatisierteTerminanfragenByThemaOutput } from '../../dtos';
import { trpc } from '../../trpc.js';
import { getBackendConfig } from '../../utils/BackendInfrastructure';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

export const AutomatisierteTerminanfragenPage: React.FC = () => {
  const [hiveUrl, setHiveUrl] = useState<string>('');

  const breadcrumbs: EasyBreadcrumbsItem[] = [
    {
      href: '/',
      label: 'Dashboard',
    },
    {
      href: '/terminanfragen/automatisiert',
      label: 'Automatiserte Terminanfragen - Statusübersicht',
    },
  ];

  useEffect(() => {
    const setUrl = async (): Promise<void> => {
      const config = await getBackendConfig();
      if (config.success) {
        setHiveUrl(config.config.HiveAppUrl);
      }
    };

    void setUrl();
  }, []);

  const themenForTermincheckQuery = trpc.reporting.getAutomatisierteTerminanfragenByThema.useQuery<GetAutomatisierteTerminanfragenByThemaOutput>();

  return (
    <Box sx={{ marginTop: 2 }}>
      <EasyBreadcrumbs items={breadcrumbs} />

      <Box sx={{ marginTop: 1.5 }}>
        <AutomatisierteTerminanfragenTable data={themenForTermincheckQuery.data ?? []} isLoading={themenForTermincheckQuery.isLoading} hiveUrl={hiveUrl} />
      </Box>
    </Box>
  );
};
