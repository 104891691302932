import { type ExperteFromPlanungRouter, type GetPlanungsinformationOutput } from '../../../../dtos';
import { type ClusterzuordnungAbbreviation, getClusterzuordnungAbbreviation } from '../../../../utils/getClusterzuordnungAbbreviation';
import { type Content, content } from './TerminExpertepoolCard.content';

export const printClusteranteil = (planungsinformation: GetPlanungsinformationOutput): string => {
  let print = '';

  if (planungsinformation.clusteranteil === null) {
    print += content.noInfoAvailable;
  } else {
    print += planungsinformation.clusteranteil;
  }

  return print;
};

export const printName = (experte?: ExperteFromPlanungRouter): string => {
  if (typeof experte === 'undefined' || (experte.vorname === null && experte.nachname === null)) {
    return content.noInfoAvailable;
  }

  if (experte.vorname === null && experte.nachname !== null) {
    return experte.nachname;
  }

  if (experte.vorname !== null && experte.nachname === null) {
    return experte.vorname;
  }

  return experte.vorname + ' ' + experte.nachname;
};

export const printAnrede = (experte?: ExperteFromPlanungRouter): string => {
  if (typeof experte === 'undefined') {
    return content.noInfoAvailable;
  }

  if (experte.anrede === null) {
    return content.noInfoAvailable;
  }

  return experte.anrede;
};

export const printTitel = (experte?: ExperteFromPlanungRouter): string => {
  if (typeof experte === 'undefined') {
    return content.noInfoAvailable;
  }

  if (experte.titel === null || experte.titel === '') {
    return content.noInfoAvailable;
  }

  return experte.titel;
};

export const printCluster = (experte?: ExperteFromPlanungRouter): ClusterzuordnungAbbreviation | Content['noInfoAvailable'] => {
  if (!experte?.clusterzuordnung) {
    return content.noInfoAvailable;
  }

  return getClusterzuordnungAbbreviation(experte.clusterzuordnung);
};

export const printIndividuelleProzentverteilung = (experte?: ExperteFromPlanungRouter): string => {
  if (typeof experte === 'undefined') {
    return content.noInfoAvailable;
  }

  if (experte.individuelleProzentVerteilung === null || experte.individuelleProzentVerteilung < 0) {
    return content.noInfoAvailable;
  }

  return experte.individuelleProzentVerteilung + content.units.percent;
};

const today = new Date();
export const thisYear = today.getFullYear();
export const nextYear = today.getFullYear() + 1;

export const printAuslastungAktuellesJahrLabel = (jahr: number): string => content.columnNames.auslastung + ' ' + jahr;

export const printAuslastungForJahr = (jahr: number, experte?: ExperteFromPlanungRouter): string => {
  if (typeof experte === 'undefined') {
    return content.noInfoAvailable;
  }

  const auslastungProJahr = experte.auslastungProJahr;

  const auslastung = auslastungProJahr.find((item) => item.jahr === jahr)?.auslastung;

  if (typeof auslastung === 'undefined') {
    return content.noInfoAvailable;
  }

  return auslastung;
};

export const printEinsatzort = (experte?: ExperteFromPlanungRouter): string => {
  if (typeof experte === 'undefined') {
    return content.noInfoAvailable;
  }

  if (experte.einsatzort === null) {
    return content.noInfoAvailable;
  }

  return experte.einsatzort;
};

export const printAusschlusszeiten = (experte?: ExperteFromPlanungRouter): string => {
  if (typeof experte === 'undefined') {
    return content.noInfoAvailable;
  }

  if (experte.ausschlusszeiten === null) {
    return content.noInfoAvailable;
  }

  return experte.ausschlusszeiten;
};

export const printEmail = (experte?: ExperteFromPlanungRouter): string => {
  if (typeof experte === 'undefined') {
    return content.noInfoAvailable;
  }

  if (experte.email === null) {
    return content.noInfoAvailable;
  }

  return experte.email;
};

export const printExperteSapId = (experte?: ExperteFromPlanungRouter): string => experte?.experteSapId.toString() ?? content.noInfoAvailable;

export const clusterField = 'cluster';

export type ExperteWithId = ExperteFromPlanungRouter & { id: number };
