import { ContentLoading } from '../../../components/general/ContentLoading';
import { EasyBreadcrumbs, type EasyBreadcrumbsItem } from '../../../components/general/EasyBreadcrumbs';
import { NoContentInfo } from '../../../components/general/NoContentInfo';
import { VeranstaltungPlanung } from '../../../components/pages/thema/VeranstaltungsPlanung';
import { type GetPlanungsinformationOutput, type GetThemaByIdOutput } from '../../../dtos';
import { trpc } from '../../../trpc';
import { Grid2, Paper, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { z } from 'zod';

export const VeranstaltungPlanungPage: React.FC = () => {
  const { themaSapId } = useParams();
  const parseThemaSapId = z.coerce.number().safeParse(themaSapId);

  let parsedThemaSapId: number | null = null;

  if (parseThemaSapId.success) {
    parsedThemaSapId = parseThemaSapId.data;
  }

  if (parsedThemaSapId === null) {
    return <NoContentInfo text="Termin konnte nicht gefunden werden" />;
  }

  const themaQuery = trpc.thema.getThemaById.useQuery<GetThemaByIdOutput>({ produktSapId: parsedThemaSapId });
  const planungsinformationQuery = trpc.planung.getPlanungsinformation.useQuery<GetPlanungsinformationOutput>({ produktSapId: parsedThemaSapId });

  if (themaQuery.error || planungsinformationQuery.error) {
    return <NoContentInfo text="Termin konnte nicht gefunden werden" />;
  }

  if (themaQuery.isLoading || planungsinformationQuery.isLoading || !themaQuery.data) {
    return <ContentLoading />;
  }

  const breadcrumbs: EasyBreadcrumbsItem[] = [
    {
      href: '/',
      label: 'Dashboard',
    },
    {
      href: '/search/thema',
      label: 'Suche',
    },
    {
      label: 'Thema',
      href: `/thema/${themaSapId}`,
    },
  ];

  return (
    <Stack sx={{ paddingTop: 2 }} spacing={2}>
      <EasyBreadcrumbs items={breadcrumbs} />
      <Paper sx={{ padding: 2 }}>
        <Grid2 container>
          <Grid2 size={{ xs: 8 }}>
            <Stack>
              <Typography variant="h4">{themaQuery.data.thema.titel}</Typography>
            </Stack>
          </Grid2>
          <Grid2 display="flex" justifyContent="end" size={{ xs: 4 }}>
            <Typography>
              Produkt-Id: {themaQuery.data.thema.produktSapId}
              <br />
              Buchungsnummer: {themaQuery.data.thema.buchungsnummer}
              <br />
              letzte Änderung: {themaQuery.data.thema.updatedAt.toLocaleDateString('de')}
            </Typography>
          </Grid2>
        </Grid2>
      </Paper>
      <VeranstaltungPlanung thema={themaQuery.data.thema} planungsinformationQuery={planungsinformationQuery} />
    </Stack>
  );
};
