import { experteBlockungQuelle } from '../../../../../../dtos.js';
import colorPalette from '../../../../../../theme/colorPalette.js';
import { type CalendarEvent, isExperteEvent } from '../../CalendarResourceOverview/helperClasses';
import { EventButton } from './EventButton';
import { ExternalSAPEvent } from './ExternalSAPEvent';
import { type InternalCalendarEvent, VivaEvent } from './VivaEvent';
import { Typography } from '@mui/material';

type DisplayCalendarEventProps = {
  readonly event: CalendarEvent;
};

const isInternalEvent = (calendarEvent: CalendarEvent): calendarEvent is InternalCalendarEvent => {
  if (isExperteEvent(calendarEvent) && calendarEvent.blockungData.quelle !== experteBlockungQuelle.VAMOS) {
    return false;
  }

  return calendarEvent.blockungData.veranstaltung !== null;
};

export const DisplayCalendarEvent: React.FC<DisplayCalendarEventProps> = ({ event }: DisplayCalendarEventProps) => {
  if (event.isCurrentSelection) {
    return (
      <EventButton eventColor={colorPalette.primary.main}>
        <Typography variant="body2">Ausgewählt</Typography>
      </EventButton>
    );
  }

  if (event.isOldSelection) {
    return (
      <EventButton eventColor={colorPalette.monochrome.grey60}>
        <Typography variant="body2">bisherige Auswahl</Typography>
      </EventButton>
    );
  }

  if (isInternalEvent(event)) {
    return <VivaEvent calendarEvent={event} />;
  }

  return <ExternalSAPEvent calendarEvent={event} />;
};
