import { RaumResourceCalendar } from '../../components/pages/reporting/RaumResourceCalendar';
import { Box, Card, CardContent, Divider, Typography } from '@mui/material';

export const RaumResourceOverviewPage: React.FC = () => (
  <Box sx={{ marginTop: 2 }}>
    <Card>
      <CardContent>
        <Typography variant="h5">Raum-Ressourcen-Übersicht</Typography>
        <Divider />
        <RaumResourceCalendar />
      </CardContent>
    </Card>
  </Box>
);
