import { getBackendConfig } from '../../../utils/BackendInfrastructure';
import { content } from './content';
import { useEffect, useState } from 'react';
import { Link } from 'react-router';

type GesellschaftCellProps = { readonly gesellschaftId: string | null };

export const GesellschaftCell: React.FC<GesellschaftCellProps> = ({ gesellschaftId }: GesellschaftCellProps) => {
  const [hiveUrl, setHiveUrl] = useState<string | null>(null);

  useEffect(() => {
    const setUrl = async (): Promise<void> => {
      const config = await getBackendConfig();
      if (config.success) {
        setHiveUrl(config.config.HiveAppUrl);
      }
    };

    void setUrl();
  }, []);

  if (gesellschaftId === null) {
    return content.nein;
  }

  if (hiveUrl === null) {
    return content.ja;
  }

  return (
    <Link target="_blank" rel="noopener" to={`${hiveUrl}/organizations/${gesellschaftId}`}>
      {content.ja}
    </Link>
  );
};
