import { LinkWithCopyButton } from '../../components/general/LinkWithCopyButton';
import { VaStatusChip } from '../../components/general/VaStatusChip';
import { determineStatusText } from '../../components/general/VaStatusChip/VaStatusChip.js';
import { type SearchVeranstaltungOutput, type SearchVeranstaltungOutputItem } from '../../dtos.js';
import { formatDate } from '../../utils/dateFormat.js';
import { experteName } from '../../utils/experteUtils.js';
import { getVeranstaltungEnd, getVeranstaltungStart } from '../../utils/getVeranstaltungStartEnd.js';
import { content, type VeranstaltungSearchHeaderField } from './VeranstaltungSearchPage.content.js';
import { DataGridPro, gridClasses, type GridColDef } from '@mui/x-data-grid-pro';
import { deDE } from '@mui/x-data-grid-pro/locales.js';

type VeranstaltungSearchDataGridProps = {
  readonly data: SearchVeranstaltungOutput;
};

export const VeranstaltungSearchDataGrid: React.FC<VeranstaltungSearchDataGridProps> = ({ data }: VeranstaltungSearchDataGridProps) => {
  const columns: Array<GridColDef<SearchVeranstaltungOutputItem>> & Array<Record<'field', VeranstaltungSearchHeaderField>> = [
    { field: 'id', type: 'number', headerName: content.headerNames.id, flex: 2, hideable: true },
    { field: 'veranstaltungSapId', type: 'string', headerName: content.headerNames.veranstaltungSapId, flex: 2 },
    {
      field: 'akaVeranstaltungId',
      type: 'string',
      headerName: content.headerNames.akaVeranstaltungId,
      flex: 2,
      renderCell: (params) => (
        <LinkWithCopyButton text={params.row.akaVeranstaltungId} to={`/thema/${params.row.produktSapId}/veranstaltung/${params.row.id}`} tooltip="Zur Veranstaltung wechseln" />
      ),
    },
    {
      field: 'buchungsnummer',
      type: 'number',
      headerName: content.headerNames.buchungsnummer,
      headerAlign: 'left',
      flex: 2,
      valueGetter: (_rawValue, row) => row.buchungsnummer ?? undefined,
      renderCell: (params) => <LinkWithCopyButton text={params.value} to={`/thema/${params.row.produktSapId}`} tooltip="Zum Thema wechseln" />,
    },
    { field: 'titel', type: 'string', headerName: content.headerNames.titel, flex: 3 },
    {
      field: 'start',
      headerName: content.headerNames.start,
      flex: 1,
      type: 'date',
      valueGetter: (_rawValue, row) => getVeranstaltungStart(row),
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: 'end',
      headerName: content.headerNames.end,
      flex: 1,
      type: 'date',
      valueGetter: (_rawValue, row) => getVeranstaltungEnd(row),
      renderCell: (params) => formatDate(params.value),
    },
    {
      field: 'status',
      headerName: content.headerNames.status,
      flex: 1,
      valueGetter: (_rawValue, row) => determineStatusText(row.sapStatus, row.vivaStatus),
      renderCell: (params) => <VaStatusChip sapStatus={params.row.sapStatus} vivaStatus={params.row.vivaStatus} />,
    },
    {
      field: 'experten',
      headerName: content.headerNames.experten,
      sortable: false,
      flex: 1,
      renderCell: (params): string => {
        const experteNamen = params.row.experten.map((experte: { vorname: string; nachname: string }) => experteName(experte)).join(', ');
        if (experteNamen) {
          return experteNamen;
        } else {
          return 'Unbekannt';
        }
      },
    },
  ];

  return (
    <DataGridPro
      pagination
      pageSizeOptions={[20, 25, 100, { value: -1, label: 'Alle' }]}
      columns={columns}
      rows={data}
      getRowHeight={() => 'auto'}
      initialState={{
        sorting: {
          sortModel: [{ field: 'id', sort: 'asc' }],
        },
        pagination: {
          paginationModel: { pageSize: 20, page: 0 },
        },
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      sx={{
        height: '100%',
        width: '100%',
        [`& .${gridClasses.cell}`]: { py: 1 },
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: { outline: 'none' },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: { outline: 'none' },
      }}
      disableRowSelectionOnClick
      localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
    />
  );
};
