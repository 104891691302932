import { type Ablauf, type AkaVeranstaltungId, type Raum, ressourcenBlockungTypes } from '../../../../../../../dtos';
import { CalendarRaumEvent } from '../CalendarEvent';
import { AbstractCalendarResource } from './AbstractCalendarResource';
import { addDays, eachDayOfInterval, endOfDay, isWithinInterval, startOfDay } from 'date-fns';

/**
 * Represents one Raum, and one row in the calendar.
 */
export class CalendarRaumResource extends AbstractCalendarResource {
  public readonly type = ressourcenBlockungTypes.RAUM;

  public readonly id: `r-${string}`;

  public readonly name: string;

  public readonly raum: Raum;

  public constructor(raum: Raum) {
    super();
    this.raum = raum;
    this.id = `r-${raum.raumSapId}`;
    this.name = `${raum.kuerzel} / ${raum.name} / ${raum.groesse} m² / ${raum.kapazitaetUForm}-${raum.kapazitaetParlamentarisch}-${raum.kapazitaetOhneTische}`;
  }

  public convertBlockungenToCalendarEvents(akaVeranstaltungId: AkaVeranstaltungId | null): CalendarRaumEvent[] {
    const events: CalendarRaumEvent[] = [];

    for (const blockung of this.raum.raumBlockungen) {
      const start = this.getBlockungStart(blockung.zeitraeume);
      const end = this.getBlockungEnd(blockung.zeitraeume);

      const newEvent = new CalendarRaumEvent(start, end, this, {
        ortKuerzel: this.raum.ort.kuerzel,
        quelleTerminId: blockung.akaVeranstaltungId,
        veranstaltung: blockung.veranstaltung,
        zeitraeume: blockung.zeitraeume,
      });

      newEvent.isOldSelection = blockung.akaVeranstaltungId === akaVeranstaltungId;

      events.push(newEvent);
    }

    return events;
  }

  public createNewCalendarEvent(date: Date, ablauf: Ablauf): CalendarRaumEvent {
    const start = startOfDay(date);
    const end = endOfDay(addDays(date, ablauf.length - 1));

    const zeitraeume: Ablauf = [];
    for (const [index] of ablauf.entries()) {
      zeitraeume.push({
        start: startOfDay(addDays(date, index)),
        end: endOfDay(addDays(date, index)),
      });
    }

    return new CalendarRaumEvent(start, end, this, {
      // unimportant values
      ortKuerzel: null,
      quelleTerminId: '',
      veranstaltung: null,

      // important values
      zeitraeume,
    });
  }

  public getRessourceSapId(): number {
    return this.raum.raumSapId;
  }

  public isAvailable(interval: { start: Date; end: Date }) {
    const days = eachDayOfInterval(interval);
    return days.every((date) => this.raum.gekaufterZeitraum.some((tag) => isWithinInterval(date, { start: startOfDay(tag.start), end: endOfDay(tag.end) })));
  }
}
