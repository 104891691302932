type ContentType = 'text/html' | 'text/plain';

export const copyToClipboard = async (copyString: string, contentType: ContentType = 'text/plain') => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- This is for firefox, it does not support this type of operation and clipboard items...
  if (navigator.clipboard.write) {
    const blobInput = new Blob([copyString], { type: contentType });
    await navigator.clipboard.write([new ClipboardItem({ [contentType]: blobInput })]);
  } else {
    await navigator.clipboard.writeText(copyString);
  }
};
