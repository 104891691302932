import { type Ablauf, type AkaVeranstaltungId, type RessourcenBlockungType } from '../../../../../../../dtos';
import { type CalendarEvent } from '../CalendarEvent';
import { type MbscResource } from '@mobiscroll/react';

/**
 * Represents one blockable Ressource in our system (either Raum or Experte or Standort), and one row in the calendar.
 */
export abstract class AbstractCalendarResource implements MbscResource {
  /**
   * Type of the Ressource.
   */
  public abstract readonly type: RessourcenBlockungType;

  /**
   * Unique row identifier. Consists of prefix plus SapId of the underlying Ressource.
   * Needed by Mobiscroll -> Don't rename.
   *
   * @example For a Raum, this would be 'r-60000503'.
   */
  public abstract readonly id: string;

  /**
   * Specifies the name of the calendar row.
   * Needed by Mobiscroll -> Don't rename.
   */
  public abstract readonly name: string;

  /**
   * Turns pre-existing Blockungen of the underlying Ressource into CalendarEvents.
   * See AbstractCalendarEvent for more info.
   */
  public abstract convertBlockungenToCalendarEvents(akaVeranstaltungId: AkaVeranstaltungId | null): CalendarEvent[];

  /**
   * Used when putting in new Blockungen for the underlying Ressource.
   */
  public abstract createNewCalendarEvent(date: Date, ablauf: Ablauf): CalendarEvent;

  public abstract getRessourceSapId(): number;

  protected getBlockungStart(zeitraeume: Ablauf): Date {
    return zeitraeume.map((tag) => tag.start).reduce((previous, current) => (current < previous ? current : previous));
  }

  protected getBlockungEnd(zeitraeume: Ablauf): Date {
    return zeitraeume.map((tag) => tag.end).reduce((previous, current) => (current > previous ? current : previous));
  }
}
