import {
  type CalendarExperteResource,
  type CalendarRaumResource,
  type CalendarResource,
  type CalendarStandortResource,
  isExperteResource,
  isRaumResource,
  isStandortResource,
} from '../../CalendarResourceOverview/helperClasses';
import { type MbscResource } from '@mobiscroll/react';
import { Box, Tooltip, Typography } from '@mui/material';

type ResourceToolTipProps = {
  readonly resource: CalendarResource;
  readonly year: number;
};

const ResourceToolTip: React.FC<ResourceToolTipProps> = ({ resource, year }: ResourceToolTipProps) => {
  if (isRaumResource(resource)) {
    return (
      <Typography variant="caption">
        Region: {resource.raum.ort.region?.name ?? '-'}
        <br />
        Standort: {resource.raum.ort.standort?.name ?? '-'}
        <br />
        Ort: {resource.raum.ort.name}
        <br />
        <br />
        Kapazität:
        <br />• U-Form: {resource.raum.kapazitaetUForm ?? '-'}
        <br />• Parlamentarisch: {resource.raum.kapazitaetParlamentarisch ?? '-'}
        <br />• ohne Tisch: {resource.raum.kapazitaetOhneTische ?? '-'}
        <br />
      </Typography>
    );
  }

  if (isStandortResource(resource)) {
    return (
      <Typography variant="caption">
        Standort: {resource.standort.name}
        <br />
      </Typography>
    );
  }

  if (isExperteResource(resource)) {
    const workloadSelectedYear = resource.experte.experteAuslastungen.find((wl) => wl.jahr === year);
    return (
      <Typography variant="caption">
        {workloadSelectedYear ? (
          <>
            Jahr: {workloadSelectedYear.jahr}
            <br />
            Gebuchte Tage: {Math.round(workloadSelectedYear.gebuchteTage)}
            <br />
            Vorgemerkte Tage: {Math.round(workloadSelectedYear.vorgemerkteTage)}
            <br />
            Summe Tage: {Math.round(workloadSelectedYear.summeTage)}
            <br />
            Schwellwert gelb: {workloadSelectedYear.schwelleGelb}
            <br />
            Schwellwert rot: {workloadSelectedYear.schwelleRot}
            <br />
          </>
        ) : (
          <span>Keine Auslastungsdaten für das ausgewählte Kalenderjahr verfügbar. Bitte prüfe HIVE für Details!</span>
        )}
      </Typography>
    );
  }

  return undefined;
};

type ExperteResourceProps = {
  readonly resource: CalendarExperteResource;
  readonly hiveUrl: string;
};

const ExperteResource: React.FC<ExperteResourceProps> = (props: ExperteResourceProps) => (
  <a style={{ textDecoration: 'none', color: 'unset' }} href={`${props.hiveUrl}/experten/${props.resource.getRessourceSapId()}/auslastung`} target="_blank" rel="noreferrer">
    <Box>
      <Typography variant="body2">{props.resource.name}</Typography>
    </Box>
  </a>
);

type RaumOrStandortResourceProps = {
  readonly resource: CalendarRaumResource | CalendarStandortResource;
};

const RaumOrStandortResource: React.FC<RaumOrStandortResourceProps> = (props: RaumOrStandortResourceProps) => (
  <Box>
    <Typography variant="body2">{props.resource.name}</Typography>
  </Box>
);

type ResourceGroupProps = {
  readonly resource: MbscResource;
};

const ResourceGroup: React.FC<ResourceGroupProps> = (props: ResourceGroupProps) => (
  <Box>
    <Typography variant="body2">{props.resource.name}</Typography>
    <Typography variant="body2">{props.resource.subline}</Typography>
  </Box>
);

type CalendarResourceProps = {
  readonly resource: CalendarResource;
  readonly year: number;
  readonly hiveUrl: string;
};

export const DisplayCalendarResource: React.FC<CalendarResourceProps> = (props: CalendarResourceProps) => {
  const { resource, year, hiveUrl } = props;

  const isResourceGroup = !isExperteResource(resource) && !isRaumResource(resource) && !isStandortResource(resource);

  return (
    <Tooltip title={isResourceGroup ? undefined : <ResourceToolTip resource={resource} year={year} />} placement="right" arrow>
      <Box sx={{ display: 'inline-block' }}>
        <>
          {isResourceGroup ? (
            <ResourceGroup resource={resource} />
          ) : (
            <>
              {isExperteResource(resource) && <ExperteResource hiveUrl={hiveUrl} resource={resource} />}
              {(isRaumResource(resource) || isStandortResource(resource)) && <RaumOrStandortResource resource={resource} />}
            </>
          )}
        </>
      </Box>
    </Tooltip>
  );
};
