import { type HomePageSearchPath, homePageSearchPaths } from '../dtos.js';
import { trpc } from '../trpc';
import { FormControlLabel, Radio, RadioGroup, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { type KeyboardEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

export const HomePage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchPath, setSearchPath] = useState<HomePageSearchPath>(homePageSearchPaths.THEMA);

  // request to start backendLambda on app open to speed up first search results
  const ping = trpc.ping.useQuery();
  const navigate = useNavigate();
  const handleKeyDown = async (event: KeyboardEvent<HTMLDivElement>): Promise<void> => {
    if (event.key === 'Enter') {
      await navigate(`/${searchPath}?searchTerm=${searchTerm}`);
    } else if (event.key === 'Tab') {
      event.preventDefault();
      if (searchPath === homePageSearchPaths.VERANSTALTUNG) {
        setSearchPath(homePageSearchPaths.THEMA);
      } else {
        setSearchPath(homePageSearchPaths.VERANSTALTUNG);
      }
    }
  };

  useEffect(() => {
    if (ping.isSuccess) {
      console.log('Backend started.');
    }
  }, [ping]);

  return (
    <div>
      <Stack alignItems="center" direction="column" justifyContent="center" sx={{ marginTop: 10, marginBottom: 10, height: '60vh' }}>
        <RadioGroup
          row
          name="homepage-suche-radio-buttons"
          value={searchPath}
          onChange={(event) => {
            setSearchPath(event.target.value as HomePageSearchPath);
          }}
        >
          <Tooltip disableInteractive title={<Typography variant="body2">Nach Terminen suchen in den Feldern "Titel", "AKA-Produkt-ID" und "BNR"</Typography>}>
            <FormControlLabel value={homePageSearchPaths.THEMA} control={<Radio />} label="Thema" />
          </Tooltip>
          <Tooltip
            disableInteractive
            title={<Typography variant="body2">Nach Veranstaltungen suchen in den Feldern "Titel", "AKA-Veranstaltung-ID", "SAP-E-ID" und "BNR"</Typography>}
          >
            <FormControlLabel value={homePageSearchPaths.VERANSTALTUNG} control={<Radio />} label="Veranstaltung" />
          </Tooltip>
        </RadioGroup>
        <TextField
          label="Suche"
          autoFocus
          onChange={(event) => setSearchTerm(event.target.value)}
          onKeyDown={handleKeyDown}
          sx={{ width: '50vw' }}
          value={searchTerm}
          variant="outlined"
        />
      </Stack>
    </div>
  );
};
