import { type GetAutomatisierteTerminanfragenByThemaOutput } from '../../../dtos.js';
import { type TypedGridColDef } from '../../../utils/gridHelper.js';
import { LinkWithCopyButton } from '../../general/LinkWithCopyButton';
import { VaStatusChip } from '../../general/VaStatusChip';
import { StatusTerminanfrageHive } from '../termin/TerminExperteCard/StatusTerminanfrageHive';
import { Box } from '@mui/material';
import { DataGridPro, type GridColDef, type GridRowParams } from '@mui/x-data-grid-pro';
import { type ReactElement, useCallback } from 'react';

type AutomatisierteTerminanfragenTableProps = {
  readonly data: GetAutomatisierteTerminanfragenByThemaOutput;
  readonly isLoading?: boolean;
  readonly hiveUrl: string;
};

const getAblaufRangeAsString = (ablauf: Array<{ start: Date; end: Date }>): string => {
  if (ablauf.length === 0) {
    return '-';
  }

  const firstRange = ablauf[0];
  const lastRange = ablauf[ablauf.length - 1];

  return `${firstRange.start.toLocaleDateString()} - ${lastRange.end.toLocaleDateString()}`;
};

export const AutomatisierteTerminanfragenTable: React.FC<AutomatisierteTerminanfragenTableProps> = ({ data, isLoading, hiveUrl }: AutomatisierteTerminanfragenTableProps) => {
  const columnsOuter: Array<TypedGridColDef<GetAutomatisierteTerminanfragenByThemaOutput[number]>> = [
    {
      field: 'buchungsnummer',
      headerName: 'Buchungsnummer',
      flex: 0.5,
      renderCell: (params): ReactElement => <LinkWithCopyButton text={params.value} to={`/thema/${params.row.produktSapId}`} tooltip="Zum Thema wechseln" />,
    },
    { field: 'titel', headerName: 'Titel', flex: 3 },
    { field: 'akaProduktId', headerName: 'AKA-Produkt-ID', flex: 0.5 },
    { field: 'verknuepfungBlopUndPraesenzseminar', headerName: 'Verknüpfung (B)LOP', flex: 0.5 },
  ];

  const columnsInner: (themaSapId: number) => Array<GridColDef<GetAutomatisierteTerminanfragenByThemaOutput[number]['veranstaltungen'][number]>> = useCallback(
    (themaSapId: number) => [
      {
        field: 'akaVeranstaltungId',
        headerName: 'AKA-Veranstaltung-ID',
        flex: 1,
        renderCell: (params): ReactElement => (
          <LinkWithCopyButton text={params.value} to={`/thema/${themaSapId}/veranstaltung/${params.row.veranstaltungId}`} tooltip="Zur Veranstaltung wechseln" />
        ),
      },
      {
        field: 'ablauf',
        headerName: 'VA-Ablauf',
        flex: 1,
        valueGetter: (ablauf: Array<{ start: Date; end: Date }>): string => getAblaufRangeAsString(ablauf),
      },
      {
        field: 'vaStatus',
        headerName: 'VA-Status',
        flex: 0.5,
        renderCell: (params): ReactElement => <VaStatusChip sapStatus={params.row.sapStatus} vivaStatus={params.row.vivaStatus} />,
      },
      {
        field: 'experteName',
        headerName: 'Trainer:in',
        flex: 1,
        renderCell: (params): ReactElement => (
          <LinkWithCopyButton
            text={`${params.row.experteBlockung.experte.vorname} ${params.row.experteBlockung.experte.nachname}`}
            to={`${hiveUrl}/experten/${params.row.experteBlockung.experteSapId}`}
            tooltip="Zu Hive wechseln"
          />
        ),
      },
      {
        field: 'experteBlockung.zeitraeume',
        headerName: 'Blockungsablauf',
        flex: 1,
        valueGetter: (_value, row): string => getAblaufRangeAsString(row.experteBlockung.zeitraeume),
      },
      {
        field: 'experteBlockung.hiveTerminanfrageStatus',
        headerName: 'Terminanfrage-Status',
        flex: 0.75,
        renderCell: (params): ReactElement => <StatusTerminanfrageHive experteBlockung={params.row.experteBlockung} />,
      },
      // TODO add when hiveTerminanfrageDatum can be received by Hive (AKAVM-1572)
      { field: 'experteBlockung.hiveTerminanfrageDatum', headerName: 'Anfragedatum', flex: 0.5, valueGetter: (): string => '-' },
      {
        field: 'experteBlockung.hiveTerminRejectedReason',
        headerName: 'Terminanfrage-Kommentar',
        flex: 1,
        valueGetter: (_value, row): string => row.experteBlockung.hiveTerminanfrageRejectedReason ?? '',
      },
    ],
    [hiveUrl],
  );

  const getDetailPanelContent = useCallback(
    (params: GridRowParams<GetAutomatisierteTerminanfragenByThemaOutput[number]>): ReactElement => (
      <Box margin={4}>
        <DataGridPro columns={columnsInner(params.row.produktSapId)} rows={params.row.veranstaltungen} />
      </Box>
    ),
    [columnsInner],
  );

  return (
    <DataGridPro
      columns={columnsOuter}
      rows={data ?? []}
      autoPageSize
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={(): 'auto' => 'auto'}
      hideFooter
      loading={isLoading}
      showCellVerticalBorder={false}
      showColumnVerticalBorder={false}
    />
  );
};
