import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {
  type Clusterzuordnung,
  clusterzuordnung,
  type RessourcenBlockungen,
  type RessourcenBlockungType,
  ressourcenBlockungTypes,
  terminart,
  type ThemaFromThemaRouter,
  type Veranstaltung,
  type VeranstaltungCustomProperties,
} from '../../../../../dtos';
import { trpc } from '../../../../../trpc';
import { getIsOnlineThema } from '../../../../../utils/getBezeichnungen';
import { FullScreen, ResourceCalendarFilters, ResourceCalendarLegend } from '../ResourceCalendar/helperComponents';
import { ResourceCalendar } from '../ResourceCalendar/ResourceCalendar';
import {
  type CollapseInfo,
  convertCurrentlySelectedCalendarEventsToBlockungen,
  convertTimeStringToDate,
  createCalendarColors,
  createCalendarResources,
  type FilterParameters,
  groupCalendarResources,
} from './CalendarResourceOverview.utils';
import { type CalendarEvent, CalendarEventHelper, type CalendarResource } from './helperClasses';
import { VeranstaltungPropertiesChanger } from './helperComponents/VeranstaltungPropertiesChanger';
import { type MbscCellClickEvent, type MbscEventClickEvent } from '@mobiscroll/react';
import { Cancel, Fullscreen, Save } from '@mui/icons-material';
import { Alert, Button, Divider, Grid2, Stack, Tooltip, Typography } from '@mui/material';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import { type ReactElement, useEffect, useMemo, useState } from 'react';

export type CalendarResourceOverviewProps = {
  readonly isFullscreen: boolean;
  readonly toggleFullscreen: () => void;
  readonly onSave: (blockungen: RessourcenBlockungen, properties?: VeranstaltungCustomProperties) => Promise<void>;
  readonly onCancel: () => void | Promise<void>;
  readonly initDate: Date;
  readonly thema: Pick<ThemaFromThemaRouter, 'id' | 'planungsinformation' | 'manuelleTerminanfrage' | 'isOnline'>;
  readonly veranstaltung: Pick<Veranstaltung, 'akaVeranstaltungId' | 'ablauf'> | null;
};

export const CalendarResourceOverview: React.FC<CalendarResourceOverviewProps> = ({
  onSave,
  onCancel,
  initDate,
  isFullscreen,
  toggleFullscreen,
  thema,
  veranstaltung,
}: CalendarResourceOverviewProps) => {
  const [filterParams, setFilterParams] = useState<FilterParameters>({
    region: [],
    standort: [],
    orte: [],
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);

  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [resources, setResources] = useState<CalendarResource[]>([]);
  const [collapseInfos, setCollapseInfos] = useState<CollapseInfo>({ raum: false, standort: true, experte: false });
  const [timeRange, setTimeRange] = useState<{ start: Date; end: Date }>({
    start: startOfMonth(initDate),
    end: endOfMonth(initDate),
  });

  const [veranstaltungCustomProperties, setVeranstaltungCustomProperties] = useState<VeranstaltungCustomProperties>({
    terminart: terminart.STANDARDTERMIN,
    manuelleTerminanfrage: thema.manuelleTerminanfrage,
  });

  const { enqueueSnackbar } = useSnackbar();

  const standorte = trpc.planung.resources.getStandorte.useQuery(
    {
      ...timeRange,
      standortSapIds: filterParams.standort,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const expertePool = trpc.planung.resources.getExpertenByThema.useQuery(
    {
      themaId: thema.id,
      ...timeRange,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const raeume = trpc.planung.resources.getRaeume.useQuery(
    {
      regionSapIds: filterParams.region,
      standortSapIds: filterParams.standort,
      ortSapIds: filterParams.orte,
      ...timeRange,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  const isOnlineThema = getIsOnlineThema(thema);

  const calendarEventHelper = useMemo(() => {
    if (veranstaltung !== null) {
      return new CalendarEventHelper(veranstaltung.ablauf);
    }

    const ablaufTageTemplate = thema.planungsinformation?.ablaufTageTemplate;

    if (!ablaufTageTemplate) {
      // todo wenn ablaufTagTemplate nicht vorhanden dann wollen wir dem User einen Fehler anzeigen: keine Planungsinformation vorhanden
      return new CalendarEventHelper([]);
    }

    const ablauf = ablaufTageTemplate.map((ablaufTagTemplate) => ({
      start: convertTimeStringToDate(ablaufTagTemplate.startZeit),
      end: convertTimeStringToDate(ablaufTagTemplate.endZeit),
    }));

    return new CalendarEventHelper(ablauf);
  }, [thema, veranstaltung]);

  useEffect(() => {
    if (expertePool.data && raeume.data && standorte.data && thema.planungsinformation) {
      const newResources = createCalendarResources(expertePool.data, raeume.data, standorte.data, timeRange.start, thema.planungsinformation);
      setResources(newResources);
      const initEvents = calendarEventHelper.initEvents(newResources, veranstaltung?.akaVeranstaltungId ?? null);

      setEvents(initEvents);
    }
  }, [expertePool.data, raeume.data, standorte.data, thema.planungsinformation, veranstaltung, calendarEventHelper, timeRange.start]);

  useEffect(() => {
    setIsSaveButtonDisabled(true);

    if (expertePool.isSuccess && raeume.isSuccess && standorte.isSuccess) {
      if (isOnlineThema) {
        setIsSaveButtonDisabled(false);
        return;
      }

      const ressourcenBlockungen = convertCurrentlySelectedCalendarEventsToBlockungen(events);
      const raumBlockungExists = ressourcenBlockungen.some((resource) => resource.type === ressourcenBlockungTypes.RAUM);
      const standortBlockungExists = ressourcenBlockungen.some((resource) => resource.type === ressourcenBlockungTypes.STANDORT);

      if (raumBlockungExists || standortBlockungExists) {
        setIsSaveButtonDisabled(false);
      }
    }
  }, [calendarEventHelper, events, filterParams, isOnlineThema, expertePool.isSuccess, raeume.isSuccess, standorte.isSuccess]);

  const onCalendarCellClicked = (args: MbscCellClickEvent): void => {
    const resourceId = args.resource;

    // this is what happens if a day in one of the row groups (Räume, Standorte, Trainer) is clicked
    if (Object.values(ressourcenBlockungTypes).includes(resourceId as RessourcenBlockungType)) {
      return;
    }

    // this is what happens if a day in one of the sub row groups in Trainer (Haupttraienr, Nebentrainer, Ersatztrainer) is clicked
    if (Object.values(clusterzuordnung).includes(resourceId as Clusterzuordnung)) {
      return;
    }

    const resource = resources.find((res) => res.id === resourceId);
    if (!resource) {
      enqueueSnackbar({ variant: 'error', message: 'Ressource nicht gefunden.' });
      return;
    }

    setEvents((prev) => {
      const res = calendarEventHelper.addEvent(resource, events, args.date);
      if (res instanceof Error) {
        enqueueSnackbar({ variant: 'error', message: res.message });
        return prev;
      }

      return res;
    });
  };

  const onCalendarEventClicked = (mbscEvent: MbscEventClickEvent): void => {
    // the eventId is, for our calendar events, always a string (see AbstractCalendarEvent)
    // however, Mobiscroll does not know this -> this function serves as a type safety wrapper
    const eventId = mbscEvent.event.id;
    if (typeof eventId === 'undefined' || typeof eventId === 'number') {
      // this implies that someone played around with the calendar events and did not update this code
      console.warn('Unknown calendar event id type');
      return;
    }

    setEvents(calendarEventHelper.removeEvent(eventId, events));
  };

  const handleSave = async (): Promise<void> => {
    setIsSaveButtonDisabled(true);
    const ressourcenBlockungen = convertCurrentlySelectedCalendarEventsToBlockungen(events);

    setEvents([]);
    await onSave(ressourcenBlockungen, veranstaltungCustomProperties);
  };

  const handleCancel = async (): Promise<void> => {
    setEvents([]);
    await onCancel();
  };

  if (!thema.planungsinformation || expertePool.error || standorte.error || raeume.error) {
    return <p>Wir haben technische Probleme :(</p>;
  }

  const handleExpansionChange = (resourceType: RessourcenBlockungType, collapsed: boolean): void => {
    setCollapseInfos((prev): CollapseInfo => {
      switch (resourceType) {
        case ressourcenBlockungTypes.EXPERTE:
          return { ...prev, experte: collapsed };
        case ressourcenBlockungTypes.RAUM:
          return { ...prev, raum: collapsed };
        case ressourcenBlockungTypes.STANDORT:
          return { ...prev, standort: collapsed };
        default:
          return prev;
      }
    });
  };

  const displaySaveTooltip = (): string | undefined => {
    if (!isOnlineThema && isSaveButtonDisabled) {
      return 'Sie müssen einen Raum oder einen Standort auswählen, um die Veranstaltung speichern zu können!';
    }

    if (isSaveButtonDisabled) {
      return 'Wir verarbeiten Ihre Daten ...';
    }

    return 'Bitte laden Sie die Seite neu.';
  };

  const renderActionButtons = (small?: boolean): ReactElement => (
    <Grid2 size={{ xs: small ? 6 : 12 }} display="flex" justifyContent="right" gap={5}>
      <Button onClick={handleCancel} size="small" startIcon={<Cancel />} color="error" sx={{ borderRadius: 1 }} variant="outlined">
        Abbrechen
      </Button>
      <Button size="small" startIcon={<Fullscreen />} sx={{ borderRadius: 1 }} variant="outlined" onClick={toggleFullscreen}>
        {isFullscreen ? 'Ansicht min' : 'Ansicht max'}
      </Button>
      <Tooltip arrow placement="top" title={displaySaveTooltip()}>
        <div>
          <Button startIcon={<Save />} size="small" onClick={handleSave} color="success" variant="contained" disabled={isSaveButtonDisabled} sx={{ borderRadius: 1, boxShadow: 1 }}>
            Speichern
          </Button>
        </div>
      </Tooltip>
    </Grid2>
  );

  const renderOnlineVeranstaltungInfo = (): ReactElement => (
    <Grid2 size={{ xs: 12 }}>
      <Alert severity="warning">
        Die Veranstaltung findet in einem Online-Format statt und aus diesem Grund können keine Räume oder Standorte gebucht werden. Es wird automatisch das Tool "Zoom" angegeben.
      </Alert>
    </Grid2>
  );

  return (
    <FullScreen isFullscreen={Boolean(isFullscreen)} withoutCloseButton>
      <Grid2 container padding={2} spacing={2}>
        {!isFullscreen && renderActionButtons(false)}

        {!isFullscreen && (
          <>
            <Grid2 size={{ xs: 12 }} sx={{ marginBottom: 0.5, marginTop: 0.5 }}>
              <Divider />
            </Grid2>

            <VeranstaltungPropertiesChanger
              themaManuelleTerminanfrage={thema.manuelleTerminanfrage}
              handleAttributeChange={(properties: VeranstaltungCustomProperties) => {
                setVeranstaltungCustomProperties(properties);
              }}
            />

            <Grid2 size={{ xs: 12 }} sx={{ marginBottom: 0.5, marginTop: 0.5 }}>
              <Divider />
            </Grid2>
          </>
        )}

        <Grid2 size={{ xs: 6 }}>
          <Typography sx={{ fontWeight: 'bold' }}>Ressourcenübersicht</Typography>
        </Grid2>

        {isFullscreen && renderActionButtons(true)}
        <Grid2 size={{ xs: 12 }}>
          <ResourceCalendarFilters
            interval={timeRange}
            onFilterChange={(filter) => {
              setFilterParams(filter);
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          <Stack alignItems="center" direction="row" justifyContent="space-between">
            <ResourceCalendarLegend />
          </Stack>
        </Grid2>
        <Grid2 size={{ xs: 12 }}>
          {getIsOnlineThema(thema) && renderOnlineVeranstaltungInfo()}
          <ResourceCalendar
            resources={groupCalendarResources(resources, collapseInfos, thema.planungsinformation, getIsOnlineThema(thema))}
            events={events}
            colors={createCalendarColors(resources, events)}
            isFullscreen={isFullscreen}
            setTimeRange={setTimeRange}
            year={timeRange.start.getFullYear()}
            toggleExpansion={handleExpansionChange}
            eventClickHandler={onCalendarEventClicked}
            cellClickHandler={onCalendarCellClicked}
          />
        </Grid2>
      </Grid2>
    </FullScreen>
  );
};
