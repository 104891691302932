import { ContentLoading } from '../../components/general/ContentLoading';
import { Droppable } from '../../components/general/dnd/Droppable';
import { EasyBreadcrumbs } from '../../components/general/EasyBreadcrumbs';
import { NoContentInfo } from '../../components/general/NoContentInfo';
import { TerminAblaufCard } from '../../components/pages/termin/TerminAblaufCard';
import { TerminExperteCard } from '../../components/pages/termin/TerminExperteCard';
import { TerminInfoCard } from '../../components/pages/termin/TerminInfoCard';
import { TerminOrtCard } from '../../components/pages/termin/TerminOrtCard';
import { TerminWarningsCard } from '../../components/pages/termin/TerminWarningsCard';
import { VeranstaltungEigenschaftenCard } from '../../components/pages/termin/VeranstaltungEigenschaftenCard';
import { ThemaHeader } from '../../components/ThemaHeader';
import { type ThemaFromThemaRouter, type Veranstaltung } from '../../dtos';
import { useNormalizedParams } from '../../routingHooks';
import { trpc } from '../../trpc';
import { parseNumberOrString } from '../../utils/number';
import { Grid2, Stack } from '@mui/material';

const validateUrlParametersOrThrow = (
  themaSapId: string | undefined,
  veranstaltungId: string | undefined,
): {
  themaSapId: number;
  veranstaltungId: number;
} => {
  if (typeof themaSapId === 'undefined') {
    throw new TypeError('Die Thema SAP Id fehlt in der URL.');
  }

  if (typeof veranstaltungId === 'undefined') {
    throw new TypeError('Die Veranstaltungs-Id fehlt in der URL.');
  }

  const parsedThemaSapId = parseNumberOrString(themaSapId);
  const parsedVeranstaltungId = parseNumberOrString(veranstaltungId);

  if (typeof parsedThemaSapId !== 'number') {
    throw new TypeError(`Die Thema SAP Id ${themaSapId} scheint keine Zahl zu sein.`);
  }

  if (typeof parsedVeranstaltungId !== 'number') {
    throw new TypeError(`Die Veranstaltungs-Id ${veranstaltungId} scheint keine Zahl zu sein.`);
  }

  return {
    themaSapId: parsedThemaSapId,
    veranstaltungId: parsedVeranstaltungId,
  };
};

export const VeranstaltungDetailsPage: React.FC = () => {
  const { themaSapId, veranstaltungId } = useNormalizedParams();

  const { themaSapId: parsedThemaSapId, veranstaltungId: parsedVeranstaltungId } = validateUrlParametersOrThrow(themaSapId, veranstaltungId);

  const themaQuery = trpc.thema.getThemaById.useQuery({ produktSapId: parsedThemaSapId });
  const veranstaltungQuery = trpc.veranstaltung.getVeranstaltungById.useQuery({ id: parsedVeranstaltungId });

  if (themaQuery.error && themaQuery.error.data?.code !== 'NOT_FOUND') {
    throw new Error('Fehler beim Laden des Themas.', { cause: themaQuery.error });
  }

  if (veranstaltungQuery.error && veranstaltungQuery.error.data?.code !== 'NOT_FOUND') {
    throw new Error('Fehler beim Laden der Veranstaltung.', { cause: veranstaltungQuery.error });
  }

  if (themaQuery.isLoading || veranstaltungQuery.isLoading) {
    return <ContentLoading />;
  }

  const thema: ThemaFromThemaRouter | undefined = themaQuery.data?.thema;
  const veranstaltung: Veranstaltung | undefined = veranstaltungQuery.data;

  if (!thema) {
    return <NoContentInfo text="Kein Thema gefunden." />;
  }

  if (!veranstaltung) {
    return <NoContentInfo text="Keine Veranstaltung gefunden." />;
  }

  return (
    <Stack padding={2} spacing={2}>
      <EasyBreadcrumbs
        items={[
          { label: 'Dashboard', href: '/' },
          { label: 'Suche', href: '/' },
          { label: 'Thema', href: `/thema/${parsedThemaSapId}` },
          { label: 'Veranstaltung', href: '' },
        ]}
      />
      <ThemaHeader thema={thema} />
      <TerminInfoCard veranstaltung={veranstaltung} />
      <TerminWarningsCard veranstaltungId={parsedVeranstaltungId} />
      <Grid2 container spacing={2} sx={{ minHeight: '50vh' }}>
        <Grid2 size={{ xs: 12, xl: 4, md: 6 }}>
          <Droppable id="left">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <TerminExperteCard key="experte" veranstaltung={veranstaltung} thema={thema} />
              <TerminAblaufCard key="ablauf" veranstaltung={veranstaltung} />
            </Stack>
          </Droppable>
        </Grid2>
        <Grid2 size={{ xs: 12, xl: 4, md: 6 }}>
          <Droppable id="mid">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <TerminOrtCard key="ort" thema={thema} veranstaltung={veranstaltung} />
            </Stack>
          </Droppable>
        </Grid2>
        <Grid2 size={{ xs: 12, xl: 4, md: 6 }}>
          <Droppable id="right">
            <Stack spacing={2} sx={{ height: '100%' }}>
              <VeranstaltungEigenschaftenCard key="eigenschaften" veranstaltung={veranstaltung} />
            </Stack>
          </Droppable>
        </Grid2>
      </Grid2>
    </Stack>
  );
};
