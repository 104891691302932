import {
  clusterzuordnung,
  type GekaufterZeitraumTag,
  type GetExpertenByThemaOutput,
  type GetRaeumeOutput,
  type GetStandorteOutput,
  type PlanungsinformationFromThemaRouter,
  type RessourcenBlockungen,
  ressourcenBlockungTypes,
} from '../../../../../dtos';
import { content } from './CalendarResourceOverview.content';
import {
  type CalendarEvent,
  CalendarExperteResource,
  CalendarRaumResource,
  type CalendarResource,
  CalendarStandortResource,
  isExperteResource,
  isRaumResource,
  isStandortResource,
} from './helperClasses';
import { type MbscCalendarColor, type MbscResource } from '@mobiscroll/react';
import { eachDayOfInterval, endOfDay, set, startOfDay } from 'date-fns';

export type FilterParameters = {
  region: number[];
  standort: number[];
  orte: number[];
};

type StandortResourceGroup = {
  id: typeof ressourcenBlockungTypes.STANDORT;
  name: typeof content.calenderResourceGroupLabels.STANDORT;
  children: CalendarStandortResource[];
} & MbscResource;

type RaumResourceGroup = {
  id: typeof ressourcenBlockungTypes.RAUM;
  name: typeof content.calenderResourceGroupLabels.RAUM;
  children: CalendarRaumResource[];
} & MbscResource;

type ExperteResourceGroup = {
  id: typeof ressourcenBlockungTypes.EXPERTE;
  name: typeof content.calenderResourceGroupLabels.EXPERTE;
  children: [
    {
      id: typeof clusterzuordnung.HAUPTTRAINER;
      name: typeof content.clusterzuordnungLabels.haupttrainer;
      children: CalendarExperteResource[];
    },
    {
      id: typeof clusterzuordnung.NEBENTRAINER;
      name: typeof content.clusterzuordnungLabels.nebentrainer;
      children: CalendarExperteResource[];
    },
    {
      id: typeof clusterzuordnung.ERSATZTRAINER;
      name: typeof content.clusterzuordnungLabels.ersatztrainer;
      children: CalendarExperteResource[];
    },
  ];
} & MbscResource;

export type CollapseInfo = {
  experte: boolean;
  raum: boolean;
  standort: boolean;
};

const getGroupDefaultValues = (): {
  experten: ExperteResourceGroup;
  raeume: RaumResourceGroup;
  standorte: StandortResourceGroup;
} => {
  const experten: ExperteResourceGroup = {
    id: ressourcenBlockungTypes.EXPERTE,
    name: content.calenderResourceGroupLabels.EXPERTE,
    collapsed: false,
    children: [
      {
        id: clusterzuordnung.HAUPTTRAINER,
        name: content.clusterzuordnungLabels.haupttrainer,
        children: [],
      },
      {
        id: clusterzuordnung.NEBENTRAINER,
        name: content.clusterzuordnungLabels.nebentrainer,
        children: [],
      },
      {
        id: clusterzuordnung.ERSATZTRAINER,
        name: content.clusterzuordnungLabels.ersatztrainer,
        children: [],
      },
    ],
  };

  const raeume: RaumResourceGroup = {
    id: ressourcenBlockungTypes.RAUM,
    name: content.calenderResourceGroupLabels.RAUM,
    children: [],
    collapsed: false,
  };

  const standorte: StandortResourceGroup = {
    id: ressourcenBlockungTypes.STANDORT,
    name: content.calenderResourceGroupLabels.STANDORT,
    children: [],
    collapsed: false,
  };

  return {
    experten,
    raeume,
    standorte,
  };
};

const getTrainerlistenWithRowNames = (
  experten: ExperteResourceGroup,
  planungsinformation: PlanungsinformationFromThemaRouter,
): {
  haupttrainerliste: ExperteResourceGroup['children'][0];
  nebentrainerliste: ExperteResourceGroup['children'][1];
  ersatztrainerliste: ExperteResourceGroup['children'][2];
} => {
  const haupttrainerliste = experten.children.find((child) => child.id === clusterzuordnung.HAUPTTRAINER);
  const nebentrainerliste = experten.children.find((child) => child.id === clusterzuordnung.NEBENTRAINER);
  const ersatztrainerliste = experten.children.find((child) => child.id === clusterzuordnung.ERSATZTRAINER);

  if (typeof haupttrainerliste === 'undefined') {
    throw new TypeError('Haupttrainerliste nicht gefunden.');
  }

  if (typeof nebentrainerliste === 'undefined') {
    throw new TypeError('Nebentrainerliste nicht gefunden.');
  }

  if (typeof ersatztrainerliste === 'undefined') {
    throw new TypeError('Ersatztrainerliste nicht gefunden.');
  }

  const clusteranteilA = planungsinformation.clusteranteil?.split('/')[0];
  const clusteranteilB = planungsinformation.clusteranteil?.split('/')[1];

  haupttrainerliste.name += ` - Cluster ${clusteranteilA}%`;
  nebentrainerliste.name += ` - Cluster ${clusteranteilB}%`;
  ersatztrainerliste.name += ' - Cluster C';

  return {
    haupttrainerliste,
    nebentrainerliste,
    ersatztrainerliste,
  };
};

export const groupCalendarResources = (
  resources: CalendarResource[],
  collapseInfo: CollapseInfo,
  planungsinformation: PlanungsinformationFromThemaRouter,
  isOnlineVeranstaltung: boolean,
): MbscResource[] => {
  const { experten, raeume, standorte } = getGroupDefaultValues();

  experten.collapsed = collapseInfo.experte;
  raeume.collapsed = collapseInfo.raum;
  standorte.collapsed = collapseInfo.standort;

  const { haupttrainerliste, nebentrainerliste, ersatztrainerliste } = getTrainerlistenWithRowNames(experten, planungsinformation);

  for (const resource of resources) {
    switch (resource.type) {
      case ressourcenBlockungTypes.EXPERTE: {
        switch (resource.experte.clusterzuordnung) {
          case clusterzuordnung.HAUPTTRAINER:
            haupttrainerliste.children.push(resource);
            break;
          case clusterzuordnung.NEBENTRAINER:
            nebentrainerliste.children.push(resource);
            break;
          case clusterzuordnung.ERSATZTRAINER:
            ersatztrainerliste.children.push(resource);
            break;
          default: {
            // error at build time if not all cases are covered
            const exhaustiveCheck: never = resource.experte.clusterzuordnung;
            throw new Error(`[CalendarResourceOverview.utils] Nicht implementierte Clusterzuordnung: ${exhaustiveCheck}`);
          }
        }

        break;
      }

      case ressourcenBlockungTypes.RAUM:
        if (!isOnlineVeranstaltung) {
          raeume.children.push(resource);
        }

        break;

      case ressourcenBlockungTypes.STANDORT:
        if (!isOnlineVeranstaltung) {
          standorte.children.push(resource);
        }

        break;

      default:
        break;
    }
  }

  const groups: Array<RaumResourceGroup | StandortResourceGroup | ExperteResourceGroup> = isOnlineVeranstaltung ? [experten] : [raeume, standorte, experten];

  return groups;
};

export const createCalendarResources = (
  expertepool: GetExpertenByThemaOutput,
  raeume: GetRaeumeOutput,
  standorte: GetStandorteOutput,
  startDate: Date,
  planungsinformation?: PlanungsinformationFromThemaRouter,
): CalendarResource[] => {
  const resources: CalendarResource[] = [];
  for (const raum of raeume) {
    resources.push(new CalendarRaumResource(raum));
  }

  for (const experte of expertepool) {
    resources.push(new CalendarExperteResource(experte, startDate.getFullYear(), planungsinformation));
  }

  for (const standort of standorte) {
    resources.push(new CalendarStandortResource(standort));
  }

  return resources;
};

type CalendarColor = MbscCalendarColor & { start: Date; end: Date };

const CALENDAR_RESOURCE_BACKGROUND_COLOR = '#DAFAC2';

const createRaumColor = (raumResource: CalendarRaumResource, availability: GekaufterZeitraumTag): CalendarColor => ({
  start: startOfDay(availability.start),
  // we remove a minute from the end date to avoid highlighting the next day (e.g. start: 2024-08-17T00:00:00, end: 2024-08-18T00:00:00)
  end: endOfDay(new Date(availability.end.valueOf() - 60_000)),
  resource: raumResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createStandortColor = (standortResource: CalendarStandortResource): MbscCalendarColor => ({
  recurring: { repeat: 'daily' },
  resource: standortResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createExperteColor = (experteResource: CalendarExperteResource): MbscCalendarColor => ({
  recurring: { repeat: 'daily' },
  resource: experteResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createEventColor = (date: Date) => ({
  start: startOfDay(date),
  end: endOfDay(date),
  background: '#C8D9FA',
});

export const createCalendarColors = (resources: CalendarResource[], events: CalendarEvent[]): MbscCalendarColor[] => {
  const colors: MbscCalendarColor[] = [];

  for (const resource of resources) {
    if (isRaumResource(resource)) {
      for (const availability of resource.raum.gekaufterZeitraum) {
        colors.push(createRaumColor(resource, availability));
      }
    }

    if (isStandortResource(resource)) {
      colors.push(createStandortColor(resource));
    }

    if (isExperteResource(resource)) {
      colors.push(createExperteColor(resource));
    }
  }

  for (const event of events) {
    if (event.isCurrentSelection) {
      for (const date of eachDayOfInterval(event)) {
        colors.push(createEventColor(date));
      }
    }
  }

  return colors;
};

export const convertCurrentlySelectedCalendarEventsToBlockungen = (events: CalendarEvent[]): RessourcenBlockungen => {
  const blockungen: RessourcenBlockungen = [];

  for (const event of events) {
    if (!event.isCurrentSelection) {
      continue;
    }

    for (const buchungsTag of eachDayOfInterval(event)) {
      blockungen.push({
        ressourceSapId: event.ressourceSapId,
        type: event.type,
        buchungsTag,
      });
    }
  }

  return blockungen;
};

export const convertTimeStringToDate = (timeString: string): Date => {
  const [hours, minutes] = timeString.split(':').map(Number);
  return set(new Date(), { hours, minutes, seconds: 0, milliseconds: 0 });
};
