import { trpc } from '../../../../../trpc';
import { type FilterParameters } from '../CalendarResourceOverview/CalendarResourceOverview.utils.js';
import { Autocomplete, Stack, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';

type ResourceCalendarFiltersProps = {
  readonly onFilterChange: (filter: FilterParameters) => void;
  readonly filterParameters?: FilterParameters;
  readonly interval: { start: Date; end: Date };
};

export const ResourceCalendarFilters: React.FC<ResourceCalendarFiltersProps> = (props: ResourceCalendarFiltersProps) => {
  const { onFilterChange, filterParameters: inputFilterParams, interval } = props;

  const [filterParameters, setFilterParameters] = useState<FilterParameters>(
    inputFilterParams ?? {
      region: [],
      standort: [],
      orte: [],
    },
  );

  const availableRegions = trpc.planung.resources.getAvailableRegionen.useQuery();
  const availableStandorte = trpc.planung.resources.getAvailableStandorteByRegion.useQuery({ regionSapIds: [] });
  const availableOrte = trpc.planung.resources.getRaeume.useQuery({
    regionSapIds: filterParameters.region,
    standortSapIds: filterParameters.standort,
    ortSapIds: [],
    start: interval.start,
    end: interval.end,
  });

  const updateFilterValue = (key: keyof FilterParameters, value: string | string[] | number | number[] | boolean): void => {
    setFilterParameters((prev) => {
      const newState = {
        ...prev,
        [key]: value,
      };
      onFilterChange(newState);

      return newState;
    });
  };

  return (
    <Stack direction="row" display="flex" spacing={2} justifyContent="space-evenly">
      <FormControl fullWidth>
        <Autocomplete
          id="region-input"
          size="small"
          multiple
          onChange={(_, newValues) => {
            updateFilterValue(
              'region',
              newValues.map((ort) => ort.regionSapId),
            );
          }}
          options={availableRegions.data ?? []}
          getOptionLabel={(opt) => opt.name}
          renderInput={(params) => <TextField {...params} label="Region" />}
        />
      </FormControl>

      <FormControl fullWidth>
        <Autocomplete
          id="standort-input"
          size="small"
          multiple
          onChange={(_, newValues) => {
            updateFilterValue(
              'standort',
              newValues.map((ort) => ort.standortSapId),
            );
          }}
          options={availableStandorte.data ?? []}
          getOptionLabel={(opt) => opt.name}
          renderInput={(params) => <TextField {...params} label="Standort" />}
        />
      </FormControl>

      <FormControl fullWidth>
        <Autocomplete
          id="ort-input"
          size="small"
          multiple
          onChange={(_, newValues) => {
            updateFilterValue(
              'orte',
              newValues.map((ort) => ort.ortSapId),
            );
          }}
          options={availableOrte.data ?? []}
          getOptionLabel={(opt) => opt.kuerzel}
          renderInput={(params) => <TextField {...params} label="Ort (Hotel)" />}
        />
      </FormControl>
    </Stack>
  );
};
