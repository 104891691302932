import { type Geschaeftsbereich } from '../../../../../../dtos.js';
import colorPalette from '../../../../../../theme/colorPalette.js';
import { getBackendConfig } from '../../../../../../utils/BackendInfrastructure';
import { formatTimeRangeForDate } from '../../../../../../utils/dateFormat.js';
import { type CalendarEvent, isExperteEvent } from '../../CalendarResourceOverview/helperClasses';
import { EventButton } from './EventButton';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

type ExternalSAPEventProps = {
  readonly calendarEvent: CalendarEvent;
};

const getGeschaeftsbereich = (calendarEvent: CalendarEvent): Geschaeftsbereich | null => {
  // only experteblockungen have a geschaeftsbereich, even when they do not point to a Veranstaltung in our system
  if (!isExperteEvent(calendarEvent)) {
    return null;
  }

  return calendarEvent.blockungData.geschaeftsbereich;
};

export const ExternalSAPEvent: React.FC<ExternalSAPEventProps> = ({ calendarEvent }: ExternalSAPEventProps) => {
  const [hiveUrl, setHiveUrl] = useState<null | string>(null);

  useEffect(() => {
    if (!isExperteEvent(calendarEvent)) {
      return;
    }

    const setUrl = async (): Promise<void> => {
      const config = await getBackendConfig();
      if (!config.success) {
        return;
      }

      const experteSapId = calendarEvent.ressourceSapId;
      setHiveUrl(`${config.config.HiveAppUrl}/experten/${experteSapId}/auslastung`);
    };

    void setUrl();
  }, [calendarEvent]);

  const gotoHive = (): void => {
    if (hiveUrl === null) {
      return;
    }

    window.open(hiveUrl, '_blank', 'noopener');
  };

  return (
    <Tooltip
      title={
        <Stack>
          <Typography variant="body2">AKA-Veranstaltung-ID: {calendarEvent.blockungData.quelleTerminId}</Typography>
          <Typography variant="body2">Geschäftsbereich: {getGeschaeftsbereich(calendarEvent) ?? '-'}</Typography>
          <Typography variant="body2">Ablaufzeiten:</Typography>
          {calendarEvent.blockungData.zeitraeume.map(({ start, end }) => (
            <Typography key={Number(start)} variant="body2" paddingLeft={1}>
              {formatTimeRangeForDate(start, end)}
            </Typography>
          ))}
          <Typography variant="body2">Achtung: Eintrag wurde in SAP gepflegt</Typography>
        </Stack>
      }
    >
      <Box>
        <EventButton eventColor={colorPalette.warning.main} sx={{ cursor: 'default' }} onClick={gotoHive}>
          <Stack>
            <Typography variant="body2" textAlign="center" textOverflow="ellipsis" overflow="hidden">
              {calendarEvent.blockungData.quelleTerminId}
            </Typography>

            <Typography variant="caption" fontSize=".5rem">
              {getGeschaeftsbereich(calendarEvent) ?? (
                <div>
                  <br />
                </div>
              )}
            </Typography>
          </Stack>
        </EventButton>
      </Box>
    </Tooltip>
  );
};
