import {
  type CreateVeranstaltungInput,
  type CreateVeranstaltungOutput,
  cservOnlineTools,
  type RessourcenBlockungen,
  terminart,
  type ThemaFromThemaRouter,
  type VeranstaltungCustomProperties,
} from '../../../dtos';
import { trpc } from '../../../trpc';
import { getIsOnlineThema } from '../../../utils/getBezeichnungen';
import { getItemFromSessionStorage } from '../../../utils/sessionStorage';
import { CalendarResourceOverview } from './planung/CalendarResourceOverview';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Paper } from '@mui/material';
import { closeSnackbar, type SnackbarAction, type SnackbarKey, useSnackbar } from 'notistack';
import { useState } from 'react';
import { useNavigate } from 'react-router';

type CreateVeranstaltungProps = {
  readonly thema: Pick<ThemaFromThemaRouter, 'id' | 'planungsinformation' | 'produktSapId' | 'manuelleTerminanfrage' | 'isOnline'>;
};

const dismissSnackbar: SnackbarAction = (snackbarId: SnackbarKey) => (
  <IconButton
    aria-label="close"
    onClick={() => {
      closeSnackbar(snackbarId);
    }}
  >
    <CloseIcon />
  </IconButton>
);

export const CreateVeranstaltung: React.FC<CreateVeranstaltungProps> = (props: CreateVeranstaltungProps) => {
  const { thema } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const navigate = useNavigate();

  const trpcUtils = trpc.useUtils();
  const createVeranstaltungMutation = trpc.veranstaltung.createVeranstaltung.useMutation({
    onSuccess: (createVeranstaltungOutput: CreateVeranstaltungOutput) => {
      enqueueSnackbar(`Veranstaltung mit ID ${createVeranstaltungOutput.veranstaltungId} gespeichert!`, { variant: 'success', persist: true, action: dismissSnackbar });
      void trpcUtils.invalidate();
    },
    onError: (error) => {
      const nodeWithoutFehlerId = (
        <div>
          <p>Es trat ein Fehler beim Speichern der Veranstaltung auf.</p>
          <p>{error.message}</p>
        </div>
      );

      const fehlerId = getItemFromSessionStorage('latest-X-Viva-Request-Id');
      const nodeWithFehlerId = (
        <div>
          <p>Es trat ein Fehler beim Speichern der Veranstaltung auf. Wende dich bitte mit Fehler-ID</p>
          <p>{fehlerId}</p>
          <p>an das VAMOS Team.</p>
          <p>{error.message}</p>
        </div>
      );

      enqueueSnackbar(fehlerId === null ? nodeWithoutFehlerId : nodeWithFehlerId, {
        variant: 'error',
        persist: true,
        action: dismissSnackbar,
      });

      void trpcUtils.invalidate();
    },
  });

  const toggleFullscreen = (): void => {
    setIsFullscreen((prev) => !prev);
  };

  const onSave = async (blockungen: RessourcenBlockungen, properties?: VeranstaltungCustomProperties): Promise<void> => {
    const input: CreateVeranstaltungInput = {
      themaId: thema.id,
      blockungen,
      terminart: properties?.terminart ?? terminart.STANDARDTERMIN,
      manuelleTerminanfrage: properties?.manuelleTerminanfrage,
      onlineTool: getIsOnlineThema(thema) ? cservOnlineTools.ZOOM : null,
    };

    await createVeranstaltungMutation.mutateAsync(input);
  };

  const onCancel = async (): Promise<void> => {
    await navigate(`/thema/${thema.produktSapId}`);
  };

  return (
    <Paper elevation={0} sx={{ width: '100%', height: '100%' }}>
      <CalendarResourceOverview
        onSave={onSave}
        onCancel={onCancel}
        thema={thema}
        isFullscreen={isFullscreen}
        toggleFullscreen={toggleFullscreen}
        initDate={new Date()}
        veranstaltung={null}
      />
    </Paper>
  );
};
