import { type GetPlanungsinformationOutput, type ThemaFromThemaRouter } from '../../../dtos';
import { TerminGenerellerKommentarCard } from '../termin/TerminGenerellerKommentarCard';
import { TerminPlanungsinformationenCard } from '../termin/TerminPlanungsinformationenCard';
import { CreateVeranstaltung } from './CreateVeranstaltung';
import { VeranstaltungenUebersichtCard } from './VeranstaltungenUebersichtCard';
import { Grid2, Paper, Stack } from '@mui/material';
import { type TRPCClientErrorBase } from '@trpc/client';
import { type UseTRPCQueryResult } from '@trpc/react-query/shared';
// no risk, no fun ;)
// TODO: change this import path as soon as the new TRPC is declared stable
import { type DefaultErrorShape } from '@trpc/server/unstable-core-do-not-import';

type VeranstaltungsPlanungProps = {
  readonly thema: ThemaFromThemaRouter;
  readonly planungsinformationQuery: UseTRPCQueryResult<GetPlanungsinformationOutput, TRPCClientErrorBase<DefaultErrorShape>>;
};

export const VeranstaltungPlanung: React.FC<VeranstaltungsPlanungProps> = ({ thema, planungsinformationQuery }: VeranstaltungsPlanungProps) => (
  <Grid2 container spacing={2}>
    <Grid2 size={{ xs: 12 }} container>
      <Grid2 size={{ xs: 3 }}>
        <Stack spacing={2}>
          <TerminPlanungsinformationenCard planungsinformationQuery={planungsinformationQuery} />
          <TerminGenerellerKommentarCard planungsinformationQuery={planungsinformationQuery} />
          <VeranstaltungenUebersichtCard themaId={thema.id} />
        </Stack>
      </Grid2>
      <Grid2 size={{ xs: 9 }}>
        <Paper sx={{ padding: '10px' }}>
          <CreateVeranstaltung thema={thema} />
        </Paper>
      </Grid2>
    </Grid2>
  </Grid2>
);
