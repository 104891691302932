import { type ThemaFromThemaRouter, type Veranstaltung, vivaStatus } from '../../../dtos';
import { getIsOnlineVeranstaltung, getOrtBezeichnung, getOrtKuerzel, getRegionName, getStandortKuerzel, getStandortName } from '../../../utils/getBezeichnungen';
import { AccordionWithHeading } from '../../general/AccordionWithHeading';
import { Tablelike } from '../../general/Tablelike';
import { BlockungenTextStack } from './BlockungenTextStack';
import { UpdateRessourcenBlockungen } from './UpdateRessourcenBlockungen';
import { Draggable } from '@mobiscroll/react';
import { Stack } from '@mui/material';
import { v4 } from 'uuid';

type TerminOrtCardProps = {
  readonly thema: ThemaFromThemaRouter;
  readonly veranstaltung: Veranstaltung;
};

const getOrtLabel = (veranstaltung: Veranstaltung): string => {
  const ortKuerzel = getOrtKuerzel(veranstaltung);
  const ortBezeichnung = getOrtBezeichnung(veranstaltung);
  const ortLabel = ortKuerzel && ortBezeichnung ? `${ortKuerzel} ${ortBezeichnung}` : null;

  if (ortLabel !== null) {
    return ortLabel;
  }

  return '';
};

const getStandortLabel = (veranstaltung: Veranstaltung): string => {
  const standortName = getStandortName(veranstaltung);
  const standortKuerzel = getStandortKuerzel(veranstaltung);
  return standortKuerzel && standortName ? `${standortKuerzel} ${standortName}` : '';
};

export const TerminOrtCard: React.FC<TerminOrtCardProps> = ({ thema, veranstaltung }: TerminOrtCardProps) => {
  if (getIsOnlineVeranstaltung(veranstaltung)) {
    return (
      <Draggable id="ort">
        <AccordionWithHeading heading="Veranstaltungsort">
          <Tablelike rows={[['Ort:', 'Zoom']]} />
        </AccordionWithHeading>
      </Draggable>
    );
  }

  return (
    <Draggable id="ort">
      <AccordionWithHeading heading="Veranstaltungsort">
        <Stack spacing={1}>
          {veranstaltung.vivaStatus === vivaStatus.INPLANUNG && <UpdateRessourcenBlockungen thema={thema} veranstaltung={veranstaltung} />}
          <Tablelike
            withDivider
            rows={[
              ['Region:', getRegionName(veranstaltung)],
              ['Standort:', getStandortLabel(veranstaltung)],
              ['Ort:', getOrtLabel(veranstaltung)],
            ]}
          />
          {veranstaltung.raumBlockungen.map((raumBlockung) => (
            <Tablelike
              key={raumBlockung.raumSapId}
              withDivider
              rows={[
                ['Raum-Art:', <b key={v4()}>Standardraum</b>],
                ['Raum-ID:', raumBlockung.raumSapId],
                ['Raum-Name:', raumBlockung.raum.name],
                ['Raum-Kürzel:', raumBlockung.raum.kuerzel],
                ['Raum-Größe:', raumBlockung.raum.groesse],
                ['Zeitraum:', <BlockungenTextStack key={v4()} zeitraeume={raumBlockung.zeitraeume} />],
              ]}
            />
          ))}
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
