import { type GetVeranstaltungByIdOutput } from '../../../dtos';
import { parseNumberOrString } from '../../../utils/number';
import { type TRPCClientErrorBase } from '@trpc/client';
import { type UseTRPCQueryResult } from '@trpc/react-query/shared';
// no risk, no fun ;)
// TODO: change this import path as soon as the new TRPC is declared stable
import { type DefaultErrorShape } from '@trpc/server/unstable-core-do-not-import';

export type VeranstaltungQuery = UseTRPCQueryResult<GetVeranstaltungByIdOutput, TRPCClientErrorBase<DefaultErrorShape>>;

export const validateAndGetVeranstaltungId = (veranstaltungIdFromParams: string | undefined): number | string | null => {
  if (typeof veranstaltungIdFromParams === 'undefined') {
    return null;
  }

  const veranstaltungId = parseNumberOrString(veranstaltungIdFromParams);
  if (typeof veranstaltungId === 'number') {
    return veranstaltungId;
  }

  // if it's not a number, roughly check if it's maybe an akaVeranstaltungId (which should start with 'V')
  // exact validation will happen in backend
  const isAkaVeranstaltungVivaId = veranstaltungId.startsWith('V');
  if (!isAkaVeranstaltungVivaId) {
    return null;
  }

  return veranstaltungId;
};
