import { Alert, AlertTitle, Backdrop, Typography } from '@mui/material';
import { useRouteError } from 'react-router';

type ErrorPageProps = {
  readonly error: unknown;
};

export const ErrorPage: React.FC<ErrorPageProps> = (props: ErrorPageProps) => {
  const { error } = props;

  let message = 'unknown Error';
  if (error instanceof Error) {
    message = error.message;
  }

  console.error(message, { cause: error });

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
      <Alert severity="error" variant="filled" sx={{ width: 'fit-content', margin: 'auto' }}>
        <AlertTitle>Oops!</AlertTitle>
        <p>Sorry, an unexpected error has occurred.</p>
        <Typography color="black" fontStyle="italic">
          {message}
        </Typography>
      </Alert>
    </Backdrop>
  );
};

export const RouterError: React.FC = () => {
  const err = useRouteError();
  return <ErrorPage error={err} />;
};
