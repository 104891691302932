export const content = {
  backendStatus: {
    error: {
      notFound: 'Es wurde keine Veranstaltung mit dieser ID gefunden.',
      generic: 'Fehler beim Laden der Daten.',
    },
    success: 'Daten aus dem Backend erfolgreich geladen! Warten auf Weiterleitung ...',
    loading: 'Daten aus dem Backend werden geladen ...',
  },
  invalidIdError: 'Keine gültige Veranstaltungs-ID angegeben.',
} as const;
