import {
  type AkaVeranstaltungId,
  experteBlockungQuelle,
  type ExperteFromResourcesRouter,
  geschaeftsbereiche,
  type PlanungsinformationFromThemaRouter,
  ressourcenBlockungTypes,
} from '../../../../../../../dtos';
import { CalendarExperteEvent } from '../CalendarEvent';
import { AbstractCalendarResource } from './AbstractCalendarResource';
import { endOfDay, startOfDay } from 'date-fns';

/**
 * Represents one Experte, and one row in the calendar.
 */
export class CalendarExperteResource extends AbstractCalendarResource {
  public readonly type = ressourcenBlockungTypes.EXPERTE;

  public readonly id: `e-${string}`;

  public readonly name: string;

  public readonly experte: ExperteFromResourcesRouter;

  public constructor(experte: ExperteFromResourcesRouter, jahr: number, planungsinformation?: PlanungsinformationFromThemaRouter) {
    super();
    this.experte = experte;
    this.id = `e-${experte.experteSapId}`;
    this.name = this.calculateExperteRowName(jahr, planungsinformation);
  }

  private calculateExperteRowName(jahr: number, planungsinformation?: PlanungsinformationFromThemaRouter): string {
    let label = this.experte.vorname + ' ' + this.experte.nachname;

    const auslastungImJahr = this.experte.experteAuslastungen.find((experteAuslastung) => experteAuslastung.jahr === jahr);
    if (auslastungImJahr) {
      label += ' / ' + Math.round(auslastungImJahr.summeTage) + '/' + Math.round(auslastungImJahr.schwelleRot);
    }

    const expertePlanungsinformation = planungsinformation?.expertePool.find((expertePlanung) => expertePlanung.experteSapId === this.experte.experteSapId);

    if (!expertePlanungsinformation) {
      return label;
    }

    if (expertePlanungsinformation.einsatzort) {
      label += ' / ' + expertePlanungsinformation.einsatzort;
    }

    if (expertePlanungsinformation.terminverteilung) {
      label += ' / ' + expertePlanungsinformation.terminverteilung + '%';
    }

    if (expertePlanungsinformation.ausschlusszeiten) {
      label += ' / Ausschlusszeiten: ' + expertePlanungsinformation.ausschlusszeiten;
    }

    return label;
  }

  public convertBlockungenToCalendarEvents(akaVeranstaltungId: AkaVeranstaltungId | null): CalendarExperteEvent[] {
    const events: CalendarExperteEvent[] = [];

    for (const blockung of this.experte.experteBlockungen) {
      const start = this.getBlockungStart(blockung.zeitraeume);
      const end = this.getBlockungEnd(blockung.zeitraeume);

      const newEvent = new CalendarExperteEvent(start, end, this, {
        ortKuerzel: blockung.ortKuerzel,
        quelleTerminId: blockung.quelleTerminId,
        veranstaltung: blockung.veranstaltung,
        zeitraeume: blockung.zeitraeume,
        geschaeftsbereich: blockung.geschaeftsbereich,
        quelle: blockung.quelle,
      });
      newEvent.isOldSelection = blockung.quelleTerminId === akaVeranstaltungId;

      events.push(newEvent);
    }

    return events;
  }

  public createNewCalendarEvent(date: Date): CalendarExperteEvent {
    // with experten, at first click, only one day is selectable -> this is why this implementation of createEvent behaves differently
    const start = startOfDay(date);
    const end = endOfDay(date);
    const event = new CalendarExperteEvent(start, end, this, {
      // unimportant values
      ortKuerzel: null,
      quelleTerminId: '',
      veranstaltung: null,
      geschaeftsbereich: geschaeftsbereiche.KFF,
      quelle: experteBlockungQuelle.VAMOS,

      // important values
      zeitraeume: [{ start, end }],
    });
    return event;
  }

  public getRessourceSapId(): number {
    return this.experte.experteSapId;
  }
}
