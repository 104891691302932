/**
 * Turns strings into numbers if able to do so, otherwise returns original string. Accepts base 10 input only.
 * Ignores trailing/leading whitespaces.
 * Does not ignore other trailing/leading non-number characters.
 *
 * @example parseNumberOrString(' 123.23') -> 123.23
 * @example parseNumberOrString('123.23 ') -> 123.23
 * @example parseNumberOrString(' 123.23 ') -> 123.23
 * @example parseNumberOrString('123.23bla') -> '123.23bla'
 * @example parseNumberOrString('bla123.23') -> 'bla123.23'
 * @example parseNumberOrString('0xF2') -> '0xF2'
 * @example parseNumberOrString('') -> ''
 * @example parseNumberOrString('käsebrot') -> 'käsebrot'
 */
export const parseNumberOrString = (value: string): number | string => {
  const valueFromParseFloat = Number.parseFloat(value);
  const valueFromNumberConstructor = Number(value);

  if (valueFromParseFloat !== valueFromNumberConstructor) {
    // '123.23bla' is turned into NaN by the Number constructor, and 123.23 by Number.parseFloat()
    // '' is turned into 0 by the Number constructor, and NaN by Number.parseFloat()
    // '0xF2' is turned into 242 by the Number constructor, and 0 by Number.parseFloat()
    // everything that will cause NaN in both, will also land here, since NaN !== NaN in JavaScript
    return value;
  }

  // in all other cases, the Number constructor and Number.parseFloat() agree with each other :)
  return valueFromParseFloat;
};
