import { type Ablauf, type AkaVeranstaltungId, type Geschaeftsbereich, type RessourcenBlockungType } from '../../../../../../../dtos';
import { type MbscCalendarEvent } from '@mobiscroll/react';
import { v7 } from 'uuid';

export type BlockungData = {
  /**
   * This is either a Kuerzel from an Ort, or a Standort.
   */
  ortKuerzel: string | null;
  /**
   * This field represents the ID of the Veranstaltung/Termin that this Blockung belongs to, as written in the system that created this Blockung (for example, SAP).
   * This is equal to the akaVeranstaltungId if the Blockung was created by us.
   *
   * StandortBlockungen can only come from our system, so it's taken from StandortBlockung -> Veranstaltung -> akaVeranstaltungId.
   *
   * RaumBlockungen and ExperteBlockungen can come from other systems than ours.
   *
   * For ExperteBlockungen, this is called 'quelleTerminId' in the database.
   * For RaumBlockungen, this is called 'akaVeranstaltungId' in the database.
   */
  quelleTerminId: string;
  /**
   * This is some data of the Veranstaltung that this Blockung belongs to, exactly as it is in the database.
   * Can be null if the Blockung came from an external system.
   */
  veranstaltung: {
    akaVeranstaltungId: AkaVeranstaltungId;
    geschaeftsbereich: Geschaeftsbereich;
    id: number;
    thema: {
      buchungsnummer: string;
      produktSapId: number;
    };
    titel: string;
    veranstaltungSapId: number | null;
  } | null;
  /**
   * This describes the zeitraeume in which the Ressource is blocked (exactly as in database). Each entry in the array is one day.
   */
  zeitraeume: Ablauf;
};

/**
 * Represents one Blockung of a Ressource in our system (either Raumblockung or Experteblockung or Standortblockung), and one event in the calendar.
 */
export abstract class AbstractCalendarEvent implements MbscCalendarEvent {
  /**
   * Type of the Blockung.
   */
  public abstract readonly type: RessourcenBlockungType;

  /**
   * Data of the Blockung - depending on the type, additional data might be stored here.
   */
  public abstract readonly blockungData: BlockungData;

  /*
   * This is the id of the CalendarResource that created this event.
   * See AbstractCalendarResource for more information.
   * Needed by Mobiscroll -> Don't rename.
   */
  public readonly resource: string;

  /**
   * Unique event identifier.
   * Needed by Mobiscroll -> Don't rename.
   */
  public readonly id: string = v7();

  /*
   * This is the sapId of the Ressource of the Blockung.
   * Needed by Mobiscroll -> Don't rename.
   */
  public readonly ressourceSapId: number;

  /**
   * We want all events to take up the entire space of the field that it is in, therefore, this value is always set to true.
   * Needed by Mobiscroll -> Don't rename.
   */
  public readonly allDay: boolean = true;

  /**
   * Start of the Blockung.
   * Needed by Mobiscroll -> Don't rename.
   */
  public start: Date;

  /**
   * End of the Blockung.
   * Needed by Mobiscroll -> Don't rename.
   */
  public end: Date;

  public isCurrentSelection: boolean = false;

  public isOldSelection: boolean = false;

  public constructor(
    start: Date,
    end: Date,
    // don't import the real CalendarResource type here - otherwise, there will by cyclic includes
    calendarResource: {
      id: string;
      getRessourceSapId: () => number;
    },
  ) {
    this.resource = calendarResource.id;
    this.ressourceSapId = calendarResource.getRessourceSapId();
    this.start = start;
    this.end = end;
  }
}
