import { trpc } from '../../../trpc.js';
import { createCalendarColors, createCalendarResources, type FilterParameters } from '../thema/planung/CalendarResourceOverview/CalendarResourceOverview.utils.js';
import { type CalendarEvent, CalendarEventHelper, type CalendarResource } from '../thema/planung/CalendarResourceOverview/helperClasses';
import { ResourceCalendarFilters, ResourceCalendarLegend } from '../thema/planung/ResourceCalendar/helperComponents';
import { ResourceCalendar } from '../thema/planung/ResourceCalendar/ResourceCalendar';
import { Grid2, Stack } from '@mui/material';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';

export const RaumResourceCalendar: React.FC = () => {
  const initDate = new Date();

  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [resources, setResources] = useState<CalendarResource[]>([]);
  const [timeRange, setTimeRange] = useState<{ start: Date; end: Date }>({
    start: startOfMonth(initDate),
    end: endOfMonth(initDate),
  });
  const [filterParams, setFilterParams] = useState<FilterParameters>({
    region: [],
    standort: [],
    orte: [],
  });

  const raeume = trpc.planung.resources.getRaeume.useQuery(
    {
      regionSapIds: filterParams.region,
      standortSapIds: filterParams.standort,
      ortSapIds: filterParams.orte,
      ...timeRange,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (raeume.data) {
      const newResources = createCalendarResources([], raeume.data, [], timeRange.start);
      setResources(newResources);
      const calendarEventHelper = new CalendarEventHelper([]);
      const initEvents = calendarEventHelper.initEvents(newResources, null);

      setEvents(initEvents);
    }
  }, [raeume.data, timeRange.start]);

  return (
    <Grid2 container padding={2} spacing={2}>
      <Grid2 size={{ xs: 12 }}>
        <ResourceCalendarFilters
          interval={timeRange}
          onFilterChange={(filter) => {
            setFilterParams(filter);
          }}
        />
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <Stack alignItems="center" direction="row" justifyContent="space-between">
          <ResourceCalendarLegend hideActionLegend />
        </Stack>
      </Grid2>
      <Grid2 size={{ xs: 12 }}>
        <ResourceCalendar
          colors={createCalendarColors(resources, events)}
          resources={resources}
          events={events}
          isFullscreen={false}
          year={timeRange.start.getFullYear()}
          initialDate={initDate}
          setTimeRange={setTimeRange}
        />
      </Grid2>
    </Grid2>
  );
};
