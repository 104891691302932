import { Box, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router';

export type EasyBreadcrumbsItem = {
  readonly label: string;
  readonly href: string;
};

type EasyBreadcrumbsProps = {
  readonly items: EasyBreadcrumbsItem[];
};

export const EasyBreadcrumbs: React.FC<EasyBreadcrumbsProps> = (props: EasyBreadcrumbsProps) => (
  <Breadcrumbs separator=">" aria-label="breadcrumb" sx={{ fontSize: '.8rem' }}>
    {props.items.map((item) => (
      <Box component={Link} key={item.label} to={item.href} sx={{ textDecoration: 'none' }}>
        {item.label}
      </Box>
    ))}
  </Breadcrumbs>
);
