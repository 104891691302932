export const content = {
  headerNames: {
    id: 'ID',
    akaVeranstaltungId: 'AKA-Veranstaltung-ID',
    veranstaltungSapId: 'SAP-E-ID',
    titel: 'Titel',
    experten: 'Experten',
    buchungsnummer: 'Buchungsnummer',
    status: 'Status',
    start: 'Startdatum',
    end: 'Enddatum',
  },
} as const;

export type VeranstaltungSearchHeaderField = keyof typeof content.headerNames;
