import { getBackendConfig } from '../../../../utils/BackendInfrastructure';
import { type ExperteWithId, printName } from './TerminExpertepoolCard.utils';
import { useEffect, useState } from 'react';
import { Link } from 'react-router';

type ExperteNameCellProps = {
  readonly experte: ExperteWithId;
};

export const ExperteNameCell: React.FC<ExperteNameCellProps> = ({ experte }: ExperteNameCellProps) => {
  const [hiveUrl, setHiveUrl] = useState<string>('');

  useEffect(() => {
    const setUrl = async (): Promise<void> => {
      const config = await getBackendConfig();
      if (config.success) {
        setHiveUrl(config.config.HiveAppUrl);
      }
    };

    void setUrl();
  }, []);

  return (
    <Link target="_blank" rel="noopener" to={`${hiveUrl}/experten/${experte.experteSapId}`}>
      {printName(experte)}
    </Link>
  );
};
