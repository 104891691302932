import { type ExperteFromReportingRouter, type GetAppointmentsOutput } from '../../../dtos';

export const defaultDateOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

const createExperteRowFromExperte = (experte: ExperteFromReportingRouter): HTMLTableRowElement => {
  const sortedBlocks = experte.blocks.sort((a, b) => (a.start?.getTime() ?? 0) - (b.start?.getTime() ?? 0));
  const experteRow = document.createElement('tr');
  experteRow.append(document.createElement('td'));
  experteRow.append(document.createElement('td'));
  const experteDataCol = document.createElement('td');
  const experteBlockungStart = sortedBlocks[0]?.start;
  const experteBlockungEnde = sortedBlocks[sortedBlocks.length - 1]?.end;
  if (experteBlockungStart && experteBlockungEnde) {
    experteDataCol.textContent = `${experte.name}: ${experteBlockungStart.toLocaleDateString('de-DE', defaultDateOptions)} - ${experteBlockungEnde.toLocaleDateString('de-DE', defaultDateOptions)}`;
  } else {
    experteDataCol.textContent = `${experte.name}: Datenfehler in Blockung`;
  }

  experteRow.append(experteDataCol);

  return experteRow;
};

export const generateClipboardTextForThema = (appointments: GetAppointmentsOutput, themaTitle: string = 'Termine ohne Thema', buchungsNummer: string = '---'): string => {
  const contentDiv = document.createElement('div');
  const header = document.createElement('h3');
  header.textContent = `${themaTitle} (${buchungsNummer})`;
  contentDiv.append(header);

  const table = document.createElement('table');
  for (const appointment of appointments) {
    const tableRow = document.createElement('tr');
    const startDate = document.createElement('td');
    startDate.textContent = appointment.startDate.toLocaleDateString('de-DE', defaultDateOptions);
    tableRow.append(startDate);
    const endDate = document.createElement('td');
    endDate.textContent = `- ${appointment.endDate.toLocaleDateString('de-DE', defaultDateOptions)}`;
    tableRow.append(endDate);
    const location = document.createElement('td');
    location.textContent = appointment.standortName ?? appointment.location ?? 'Ortname unbekannt';
    tableRow.append(location);
    table.append(tableRow);

    const isSomeExperteDifferentInAblauf = appointment.experten.some((experte) => experte.blocks.length !== appointment.ablauf.length);
    if (isSomeExperteDifferentInAblauf) {
      for (const experte of appointment.experten) {
        const experteRow = createExperteRowFromExperte(experte);
        table.append(experteRow);
      }
    } else {
      const experteCol = document.createElement('td');
      experteCol.textContent = appointment.experten.map((experte) => experte.name).join(', ');
      tableRow.append(experteCol);
    }
  }

  contentDiv.append(table);
  const copyString = contentDiv.innerHTML;
  contentDiv.remove();

  return copyString;
};
