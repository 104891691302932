import { trpc } from '../../../trpc';
import { BackendResultDisplay } from './BackendResultDisplay';
import { type VeranstaltungQuery } from './Reroute.utils';
import { useEffect } from 'react';
import { type NavigateFunction, useNavigate } from 'react-router';

type RerouteVeranstaltungProps = { readonly veranstaltungId: string | number };

export const RerouteVeranstaltung: React.FC<RerouteVeranstaltungProps> = ({ veranstaltungId }: RerouteVeranstaltungProps) => {
  const navigate: NavigateFunction = useNavigate();
  const veranstaltungQuery: VeranstaltungQuery = trpc.veranstaltung.getVeranstaltungById.useQuery(
    typeof veranstaltungId === 'string' ? { akaVeranstaltungId: veranstaltungId } : { id: veranstaltungId },
  );

  useEffect(() => {
    if (!veranstaltungQuery.isSuccess) {
      return;
    }

    const redirectPath = `/thema/${veranstaltungQuery.data.thema.produktSapId}/veranstaltung/${veranstaltungQuery.data.id}`;
    // this line wrongfully causes a sonar issue: https://github.com/SonarSource/SonarJS/issues/2629
    void navigate(redirectPath);
  }, [navigate, veranstaltungQuery]);

  return <BackendResultDisplay veranstaltungQuery={veranstaltungQuery} />;
};
