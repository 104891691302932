import { content } from './content';
import { type VeranstaltungQuery } from './Reroute.utils';

type BackendResultDisplayProps = {
  readonly veranstaltungQuery: VeranstaltungQuery;
};
export const BackendResultDisplay: React.FC<BackendResultDisplayProps> = ({ veranstaltungQuery }: BackendResultDisplayProps) => {
  if (veranstaltungQuery.isError) {
    if (veranstaltungQuery.error.data?.code === 'NOT_FOUND') {
      return <div>{content.backendStatus.error.notFound}</div>;
    }

    if (veranstaltungQuery.error.data?.code === 'UNPROCESSABLE_CONTENT') {
      return <div>{content.invalidIdError}</div>;
    }

    return <div>{content.backendStatus.error.generic}</div>;
  }

  if (veranstaltungQuery.isSuccess) {
    return <div>{content.backendStatus.success}</div>;
  }

  return <div>{content.backendStatus.loading}</div>;
};
