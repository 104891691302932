import { type GetManuelleTerminanfragenOutput, type ManuelleTerminanfrage } from '../../../dtos';
import { copyToClipboard } from '../../../utils/copyToClipboard.js';
import { type TypedGridColDef } from '../../../utils/gridHelper';
import { defaultDateOptions, generateClipboardTextForThema } from './ManuelleTerminanfragenThemaRow.utils';
import { CopyAllSharp } from '@mui/icons-material';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import { deDE } from '@mui/x-data-grid-pro/locales';

const appointmentsCheckColDefs: Array<TypedGridColDef<ManuelleTerminanfrage>> = [
  {
    field: 'startDate',
    headerName: 'Startdatum',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (value: Date) => value.toLocaleDateString('de-DE', defaultDateOptions),
  },
  {
    field: 'endDate',
    headerName: 'Enddatum',
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (value: Date) => value.toLocaleDateString('de-DE', defaultDateOptions),
  },
  {
    field: 'akaVeranstaltungId',
    headerName: 'AKA-VeranstaltungId',
    width: 150,
    editable: false,
    type: 'string',
  },
  {
    field: 'location',
    headerName: 'Ort',
    width: 350,
    editable: false,
    type: 'string',
    renderCell: (params) => (params.row.onlineTool ? 'Online' : params.row.location),
  },
  {
    field: 'ortsKuerzel',
    headerName: 'Ortskürzel',
    width: 150,
    editable: false,
    type: 'string',
    renderCell: (params) => params.row.onlineTool ?? params.row.ortsKuerzel,
  },
  {
    field: 'standortName',
    headerName: 'Standort',
    width: 150,
    editable: false,
    type: 'string',
  },
  {
    field: 'experten',
    headerName: 'Trainer',
    width: 300,
    editable: false,
    type: 'string',
    renderCell: (params) => params.row.experten.map((tra) => tra.name).join(', '),
  },
];

type ThemaRowCardProps = {
  readonly data: GetManuelleTerminanfragenOutput;
  readonly themaTitle?: string;
  readonly buchungsnummer?: string;
};

export const ThemaRowCard: React.FC<ThemaRowCardProps> = ({ data, themaTitle, buchungsnummer }: ThemaRowCardProps) => {
  const copySingleElementToClipboard = async (): Promise<void> => {
    await copyToClipboard(generateClipboardTextForThema(data, themaTitle, buchungsnummer), 'text/html');
  };

  return (
    <Paper>
      <Box sx={{ padding: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            {themaTitle} ({buchungsnummer})
          </Typography>
          <Button onClick={async () => await copySingleElementToClipboard()}>
            <CopyAllSharp /> Kopieren
          </Button>
        </Stack>
        <DataGridPro
          pagination
          sx={{
            marginTop: 2,
            height: 400,
            width: '100%',
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none',
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: 'none',
            },
          }}
          columns={appointmentsCheckColDefs}
          rows={data.map((item, idx) => ({ id: idx, ...item }))}
          disableRowSelectionOnClick
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </Paper>
  );
};
