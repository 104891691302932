import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Stack direction="column" spacing={4}>
      <Typography variant="h3">Diese Seite gibt es nicht 😅</Typography>
      <Button
        onClick={async () => {
          await navigate(-1);
        }}
        variant="outlined"
      >
        Zurück wo ich her kam
      </Button>
    </Stack>
  );
};
