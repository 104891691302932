import { ExperteSearchAutocomplete } from '../../components/general/ExperteSearchAutocomplete';
import { ThemaRowCard } from '../../components/pages/reporting/AppointmentCheckThemaRow';
import { generateClipboardTextForThema } from '../../components/pages/reporting/AppointmentCheckThemaRow.utils';
import { type Appointment, type AppointmentStatus, type GetAppointmentsOutput, vivaStatus } from '../../dtos';
import { trpc } from '../../trpc';
import { copyToClipboard } from '../../utils/copyToClipboard.js';
import { Box, Button, Card, CardContent, CircularProgress, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { z } from 'zod';

type QueryMode = 'BY_THEMA' | 'BY_EXPERTE' | 'BY_THEMA_AND_EXPERTE';

const parseNumberFromEvent = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, callback: (val: number) => void): void => {
  const value = Number.parseInt(event.target.value, 10);
  if (!Number.isNaN(value)) {
    callback(value);
  }
};

const storeDateInLocalStorage = (key: string, value: Date | null): void => {
  if (!value) {
    localStorage.removeItem(key);
    return;
  }

  try {
    localStorage.setItem(key, value.toISOString());
  } catch (error) {
    console.error('Error while storing key in localStorage', error);
  }
};

const loadDateFromLocalStorage = (key: string): Date | null => {
  const value = localStorage.getItem(key);
  if (!value) {
    return null;
  }

  const parsedDate = z.coerce.date().safeParse(value);
  if (parsedDate.success) {
    return parsedDate.data;
  } else {
    return null;
  }
};

type ModeFilterProps = {
  readonly queryMode: QueryMode;
  readonly setQueryMode: React.Dispatch<React.SetStateAction<QueryMode>>;
};

const ModeFilter: React.FC<ModeFilterProps> = (props: ModeFilterProps) => {
  const { queryMode, setQueryMode } = props;

  return (
    <FormControl fullWidth>
      <InputLabel>Modus</InputLabel>
      <Select value={queryMode} label="Modus" onChange={(event) => setQueryMode(event.target.value as QueryMode)}>
        <MenuItem value="BY_THEMA">Alle Termine eines Themas</MenuItem>
        <MenuItem value="BY_EXPERTE">Alle Termine eines Trainer</MenuItem>
        <MenuItem value="BY_THEMA_AND_EXPERTE">Alle Termine eines Themas und eines bestimmten Trainers</MenuItem>
      </Select>
    </FormControl>
  );
};

type StatusFilterProps = {
  readonly vaStatus: AppointmentStatus;
  readonly setVaStatus: React.Dispatch<React.SetStateAction<AppointmentStatus>>;
};

const StatusFilter: React.FC<StatusFilterProps> = (props: StatusFilterProps) => {
  const { vaStatus, setVaStatus } = props;

  return (
    <FormControl fullWidth>
      <InputLabel>Status</InputLabel>
      <Select value={vaStatus} label="Status" onChange={(event) => setVaStatus(event.target.value as AppointmentStatus)} multiple>
        <MenuItem key={vivaStatus.INPLANUNG} value={vivaStatus.INPLANUNG}>
          In Planung
        </MenuItem>
        <MenuItem key={vivaStatus.ABGESCHLOSSEN} value={vivaStatus.ABGESCHLOSSEN}>
          Planung abgeschlossen
        </MenuItem>
        <MenuItem key={vivaStatus.FREIGEGEBEN} value={vivaStatus.FREIGEGEBEN}>
          Planung freigegeben
        </MenuItem>
      </Select>
    </FormControl>
  );
};

type DateFilterProps = {
  readonly startDate: Date | null;
  readonly endDate: Date | null;
  readonly setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  readonly setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
};

const DateFilter: React.FC<DateFilterProps> = (props: DateFilterProps) => {
  const { startDate, endDate, setStartDate, setEndDate } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label="Beginndatum"
        format="dd.MM.yyyy"
        value={startDate}
        onChange={(newValue) => {
          storeDateInLocalStorage('appointmentCheckStartDate', newValue);
          setStartDate(newValue);
        }}
        slotProps={{
          textField: {
            placeholder: 'Beginndatum',
            sx: { width: '100%' },
          },
        }}
      />
      <DesktopDatePicker
        label="Enddatum"
        format="dd.MM.yyyy"
        value={endDate}
        onChange={(newValue) => {
          storeDateInLocalStorage('appointmentCheckEndDate', newValue);
          setEndDate(newValue);
        }}
        slotProps={{
          textField: {
            placeholder: 'Beginndatum',
            sx: { width: '100%' },
          },
        }}
      />
    </LocalizationProvider>
  );
};

type ModeFilterOptionsProps = {
  readonly themaSapId: number | undefined;
  readonly setThemaSapId: React.Dispatch<React.SetStateAction<number | undefined>>;
  readonly queryMode: QueryMode;
  readonly buchungsnummer: string;
  readonly setBuchungsnummer: React.Dispatch<React.SetStateAction<string>>;
  readonly setSelectedExperteId: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const ModeFilterOptions: React.FC<ModeFilterOptionsProps> = (props: ModeFilterOptionsProps) => {
  const { themaSapId, setThemaSapId, queryMode, buchungsnummer, setBuchungsnummer, setSelectedExperteId } = props;

  return (
    <>
      {(queryMode === 'BY_EXPERTE' || queryMode === 'BY_THEMA_AND_EXPERTE') && <ExperteSearchAutocomplete onSelect={(experte) => setSelectedExperteId(experte?.experteSapId)} />}
      {(queryMode === 'BY_THEMA' || queryMode === 'BY_THEMA_AND_EXPERTE') && (
        <>
          <TextField
            fullWidth
            label="Thema SAP-ID"
            value={themaSapId}
            onChange={(event) => {
              if (event.target.value.length === 0) {
                setThemaSapId(undefined);
              } else {
                parseNumberFromEvent(event, setThemaSapId);
              }
            }}
          />
          <TextField fullWidth label="BNR" value={buchungsnummer} onChange={(event) => setBuchungsnummer(event.target.value)} />
        </>
      )}
    </>
  );
};

export const AppointmentCheckPage: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(loadDateFromLocalStorage('appointmentCheckStartDate'));
  const [endDate, setEndDate] = useState<Date | null>(loadDateFromLocalStorage('appointmentCheckEndDate'));
  const [vaStatus, setVaStatus] = useState<AppointmentStatus>([vivaStatus.INPLANUNG]);

  const [queryMode, setQueryMode] = useState<QueryMode>('BY_THEMA');

  const [selectedExperteId, setSelectedExperteId] = useState<number>();
  const [themaSapId, setThemaSapId] = useState<number | undefined>(undefined);
  const [buchungsnummer, setBuchungsnummer] = useState<string>('');
  const getAppointmentsMute = trpc.reporting.getAppointments.useMutation();

  const { enqueueSnackbar } = useSnackbar();

  const runQuery = async (): Promise<void> => {
    if (!startDate || !endDate) {
      enqueueSnackbar('Bitte wählen Sie ein Start- und Enddatum aus', { variant: 'error' });
      return;
    }

    if (queryMode === 'BY_EXPERTE' && !selectedExperteId) {
      enqueueSnackbar('Bitte wählen sie einen Trainer aus', { variant: 'error' });
      return;
    }

    if (queryMode === 'BY_THEMA' && themaSapId && buchungsnummer) {
      enqueueSnackbar('Bitte geben Sie entweder eine Thema SAP-ID oder BNR ein', { variant: 'error' });
      return;
    }

    if (queryMode === 'BY_THEMA' && !themaSapId && !buchungsnummer) {
      enqueueSnackbar('Bitte geben Sie eine Thema SAP-ID oder BNR ein', { variant: 'error' });
      return;
    }

    if (queryMode === 'BY_THEMA_AND_EXPERTE' && !selectedExperteId) {
      enqueueSnackbar('Bitte geben Sie eine Trainer SAP-ID ein', { variant: 'error' });
      return;
    }

    if (queryMode === 'BY_THEMA_AND_EXPERTE' && !themaSapId && !buchungsnummer) {
      enqueueSnackbar('Bitte geben Sie eine Thema SAP-ID SAP-ID oder BNR ein', { variant: 'error' });
      return;
    }

    // setup timeframe correctly
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);

    const result = await getAppointmentsMute.mutateAsync({
      status: vaStatus,
      startDate,
      endDate,
      mode: queryMode,
      buchungsnummer: buchungsnummer.length > 0 ? buchungsnummer : undefined,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      experteSapId: selectedExperteId!,
      themaSapId,
    });

    console.log('AppointmentQuery', result);
    if (result.length === 0) {
      enqueueSnackbar('Keine Daten zur Anfrage gefunden', { variant: 'info' });
    }
  };

  const appointmentsPerThema = useMemo(() => {
    if (!getAppointmentsMute.data) {
      return null;
    }

    const result = new Map<string, GetAppointmentsOutput>();
    const appointmentsWithoutThema: Appointment[] = [];
    for (const item of getAppointmentsMute.data) {
      const buchungsNummer = item.thema?.buchungsnummer;
      if (!buchungsNummer) {
        appointmentsWithoutThema.push(item);
        continue;
      }

      if (result.has(buchungsNummer)) {
        result.get(buchungsNummer)?.push(item);
      } else {
        result.set(buchungsNummer, [item]);
      }
    }

    return {
      withoutThema: appointmentsWithoutThema,
      data: result,
    };
  }, [getAppointmentsMute.data]);

  const copyAllAppointmentsToClipboard = async (result: { data: Map<string, GetAppointmentsOutput>; withoutThema: GetAppointmentsOutput }): Promise<void> => {
    const appointmentCopies = Array.from(result.data).map(([buchungsNummer, appointments]) =>
      generateClipboardTextForThema(appointments, appointments[0].thema?.title ?? 'Thema hat keinen Titel', buchungsNummer),
    );
    appointmentCopies.push(generateClipboardTextForThema(result.withoutThema));

    const copyAllString = appointmentCopies.join('\n<br/>\n');

    await copyToClipboard(copyAllString, 'text/html');
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <Card>
        <CardContent>
          <Typography variant="h5">Manuelle Terminanfrage - Datenbereitstellung</Typography>
          <Divider />
          <Stack spacing={2} sx={{ marginTop: 2 }} direction="row">
            <DateFilter startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
            <StatusFilter vaStatus={vaStatus} setVaStatus={setVaStatus} />
          </Stack>
          <Stack sx={{ marginTop: 2 }} spacing={2} direction="row">
            <ModeFilter queryMode={queryMode} setQueryMode={setQueryMode} />
            <ModeFilterOptions
              themaSapId={themaSapId}
              setThemaSapId={setThemaSapId}
              queryMode={queryMode}
              buchungsnummer={buchungsnummer}
              setBuchungsnummer={setBuchungsnummer}
              setSelectedExperteId={setSelectedExperteId}
            />
            <Button sx={{ width: '20%' }} variant="outlined" disabled={getAppointmentsMute.isPending} onClick={async () => await runQuery()}>
              {getAppointmentsMute.isPending && <CircularProgress sx={{ marginRight: 1 }} size={20} />} Start
            </Button>
          </Stack>
        </CardContent>
      </Card>
      {((appointmentsPerThema?.data && appointmentsPerThema.data.size > 0) || (appointmentsPerThema?.withoutThema && appointmentsPerThema.withoutThema.length > 0)) && (
        <Stack sx={{ marginTop: 1 }} direction="row" justifyContent="end">
          <Button onClick={async () => await copyAllAppointmentsToClipboard(appointmentsPerThema)}>Alles Kopieren</Button>
        </Stack>
      )}
      <Box>
        <Stack direction="column" spacing={2} sx={{ marginTop: 2 }}>
          {appointmentsPerThema &&
            Array.from(appointmentsPerThema.data).map(([buchungsNummer, data]) => (
              <ThemaRowCard key={buchungsNummer} data={data} themaTitle={data[0].thema?.title ?? 'Thema hat keinen Titel'} buchungsnummer={buchungsNummer} />
            ))}
          {appointmentsPerThema && appointmentsPerThema.withoutThema.length > 0 && (
            <ThemaRowCard data={appointmentsPerThema.withoutThema} themaTitle="Termine ohne Thema" buchungsnummer="---" />
          )}
        </Stack>
      </Box>
    </Box>
  );
};
