import { copyToClipboard } from '../../utils/copyToClipboard.js';
import { ContentCopy } from '@mui/icons-material';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { type MouseEvent as ReactMouseEvent, useState } from 'react';
import { Link } from 'react-router';

type LinkWithCopyButtonProps = {
  readonly text: string;
  readonly linkAddress: string;
  readonly tooltip: string;
};

const styles = {
  link: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
  },
  button: {
    marginTop: '-8px',
    marginBottom: '-8px',
    marginLeft: '-4px',
  },
  buttonIcon: {
    fontSize: '1rem',
  },
};

const defaultTooltip = 'Klicken zum Kopieren';
const copySuccessTooltip = 'Kopiert';

export const LinkWithCopyButton: React.FC<LinkWithCopyButtonProps> = ({ text, linkAddress, tooltip }: LinkWithCopyButtonProps) => {
  const [tooltipTitle, setTooltipTitle] = useState(defaultTooltip);
  const clickHandler = (event: ReactMouseEvent): void => {
    event.stopPropagation();
    void copyToClipboard(text);
    setTooltipTitle(copySuccessTooltip);
  };

  return (
    <Stack direction="row" alignItems="center">
      <Box sx={styles.link}>
        <Tooltip title={tooltip} arrow>
          <Link to={linkAddress}>{text}</Link>
        </Tooltip>
      </Box>
      <Tooltip title={tooltipTitle} onOpen={() => setTooltipTitle(defaultTooltip)}>
        <IconButton sx={styles.button} onClick={clickHandler} color="primary">
          <ContentCopy sx={styles.buttonIcon} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};
