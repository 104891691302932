import { useNormalizedParams } from '../../../routingHooks';
import { content } from './content';
import { validateAndGetVeranstaltungId } from './Reroute.utils';
import { RerouteVeranstaltung } from './RerouteVeranstaltung';

export const Reroute: React.FC = () => {
  const { veranstaltungId: veranstaltungIdFromParams } = useNormalizedParams();

  const veranstaltungId = validateAndGetVeranstaltungId(veranstaltungIdFromParams);

  if (veranstaltungId === null) {
    return <div>{content.invalidIdError}</div>;
  }

  return <RerouteVeranstaltung veranstaltungId={veranstaltungId} />;
};
