import { type BackendConfigJson, backendConfigJsonSchema } from './backendConfigJsonSchema';

export type GetBackendConfigResult =
  | {
      success: true;
      config: BackendConfigJson;
    }
  | {
      success: false;
      errorCode: 'FETCH_FAILED' | 'PARSE_FAILED';
    };

let backendConfig: BackendConfigJson | undefined;

export const getBackendConfig = async (): Promise<GetBackendConfigResult> => {
  if (backendConfig) {
    return {
      success: true,
      config: backendConfig,
    } as const;
  }

  if (window.location.hostname === 'localhost') {
    backendConfig = {
      AppBackendUrl: import.meta.env.VITE_APP_BACKEND_URL ?? 'http://localhost:4444/api',
      AzureAdClientId: '77c77ea6-1f7d-470c-85eb-10fdf813846e',
      AzureAdTenantId: '88d0a5a9-d61a-4dd6-9a75-6637f3fe6bd8',
      AzureAdRedirectUri: 'https://va.vamos.ban.akadev.de/auth/redirect',
      VivaLegacyAppUrl: 'viva.vamos.ban.akadev.de', // legacy app main URL, since it's currently not planned to start legacy app locally
      HiveAppUrl: 'https://hive.prml.ban.akadev.de',
    };

    console.log('[BackendInfrastructure] resolved config locally', { backendConfig });
    return {
      success: true,
      config: backendConfig,
    } as const;
  }

  const result = await fetch('/backend-config.json');
  if (result.status !== 200) {
    return {
      success: false,
      errorCode: 'FETCH_FAILED',
    } as const;
  }

  const config = await result.json();
  const parsedConfig = backendConfigJsonSchema.safeParse(config);
  if (!parsedConfig.success) {
    return {
      success: false,
      errorCode: 'PARSE_FAILED',
    } as const;
  }

  // eslint-disable-next-line require-atomic-updates
  backendConfig = parsedConfig.data;
  return {
    success: true,
    config: parsedConfig.data,
  } as const;
};

const fetchWithTimeout = async (resource: string, timeout: number) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    signal: controller.signal,
    mode: 'no-cors',
  });
  clearTimeout(id);

  return response;
};

export const checkIfBackendIsReachable = async (): Promise<boolean> => {
  const backCfg = await getBackendConfig();
  if (!backCfg.success) {
    return false;
  }

  try {
    await fetchWithTimeout(backCfg.config.AppBackendUrl, 4_000);
    return true;
  } catch {
    return false;
  }
};
